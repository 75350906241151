<template>
  <el-config-provider :locale="locale">
    <router-view />
  </el-config-provider>
</template>
<script>
import { onBeforeUnmount, onMounted } from 'vue'
import zhCn from 'element-plus/lib/locale/lang/zh-cn'
import WOW from 'wow.js'
export default {
  name: 'MyApp',
  components: {
    // HelloWorld
  },
  data() {
    return {
      locale: zhCn, // 插件语言改为中文
    }
  },
  setup() {
    onMounted(() => {
      document.body.style.setProperty('--el-color-primary', '#00ABC8') //重点：直接重置颜色
      document.body.style.setProperty('--el-color-primary-light-9', '#fff')
      document.body.style.setProperty('--el-color-primary-light-3', '#00ABC8')
      // document.body.style.setProperty('--el-color-info', '#00ABC8')
      document.body.style.setProperty('--el-color-primary-light-5', '#00ABC8')
    })
  },
  mounted() {
    var wow = new WOW({
      boxClass: 'wow',
      animateClass: 'animated',
      offset: 0,
      mobile: true,
      live: true,
      callback: function (box) {},
      scrollContainer: null,
      resetAnimation: true,
    })
    wow.init()
  },
}
</script>

<style>
html,
body,
#app {
  height: 100%;
}
.el-tabs__nav-wrap::after {
  height: 1px !important;
}
/* .el-main {
  margin-bottom: 20px;
} */
.el-button--primary:hover {
  background-color: rgb(0 171 200 / 80%) !important;
}
.login_con .el-input__wrapper {
  padding: 4px 11px !important;
}
.w1280 {
  width: 1280px;
  margin: 0 auto;
}
.developer-layout .el-menu--horizontal > .el-sub-menu .el-sub-menu__title {
  color: #ccc;
}
.developer-layout
  .el-menu--horizontal
  > .el-sub-menu.is-active
  .el-sub-menu__title {
  border-bottom: 2px solid #2ebad2 !important;
  color: #2ebad2 !important;
}
.developer-layout
  .el-menu--horizontal
  > .el-sub-menu.is-active
  .el-sub-menu__title
  > .bg-color {
  color: #2ebad2 !important;
}
.el-menu--horizontal > .el-sub-menu .el-sub-menu__title:hover {
  background-color: transparent !important;
}
.el-menu--horizontal > .el-sub-menu:hover .el-sub-menu__title,
.el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
  color: #00abc8 !important;
}
.el-menu--horizontal .el-menu .el-menu-item,
.el-menu--horizontal .el-menu .el-sub-menu__title {
  justify-content: center;
}
.el-menu--popup {
  min-width: 120px !important;
}
.el-menu--horizontal .el-menu .el-menu-item.is-active,
.el-menu--horizontal .el-menu .el-sub-menu.is-active > .el-sub-menu__title {
  color: #2ebad2 !important;
}
.m_login .el-tabs__item.is-active {
  color: #00abc8;
}
.m_login .el-tabs__active-bar {
  background-color: #00abc8;
}
.case-video {
  position: relative;
}
.case-video .el-dialog__header {
  padding: 0;
}
.case-video .el-dialog__body {
  padding: 0;
}
.case-video .el-dialog__headerbtn {
  z-index: 10000;
}
.case-video .button_box {
  position: absolute;
  top: 60%;
  left: 50%;
  margin-left: -97px;
}
.case-video .el-button--primary {
  width: 98px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 32px;
  color: #ffffff;
  border: 0;
}
.video-type .el-dropdown-menu__item:not(.is-disabled):focus {
  background-color: transparent !important;
  color: #00abc8 !important;
}
.el-pagination.is-background .el-pager li.is-active {
  --el-color-primary: #00abc8 !important;
}
.el-dialog__headerbtn .el-dialog__close {
  color: #000 !important;
}
.el-menu-tools {
  width: 67.1%;
  margin: -35px auto 0 !important;
  background: #ffffff;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  border-bottom: 0 !important;
}
.el-menu-tools .el-menu-item {
  padding: 0 2%;
}
.iframe-con {
  width: 100%;
  height: calc(100vh - 316px);
  overflow-y: hidden;
}
.ask-q-dis .el-dialog__headerbtn .el-dialog__close {
  color: #131414 !important;
}
.ask-q-dis .dialog_con .el-checkbox-button__inner {
  border: 1px solid #e9e9eb;
  border-radius: 2px;
  /* background: #f4f4f5; */
}
.ask-q-dis .el-form-item__label {
  color: #303133;
  font-size: 16px;
  line-height: 24px;
}
.ask-q-dis .el-dialog__title {
  color: #303133;
  font-size: 18px;
  line-height: 26px;
}
.el-button.is-link {
  color: #00abc8 !important;
}
.details .el-breadcrumb__inner a,
.details .el-breadcrumb__inner.is-link {
  color: #00abc8;
}
/* 编辑器汉化 */
/* .ql-editor p {
  margin: 10px!important;
} */
.quill-editor .ql-container {
  height: 1000px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: '14px' !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='small']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='small']::before {
  content: '10px' !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='large']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='large']::before {
  content: '18px' !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='huge']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='huge']::before {
  content: '32px' !important;
}

.ql-snow .ql-picker.ql-header .ql-picker-label::before,
.ql-snow .ql-picker.ql-header .ql-picker-item::before {
  content: '文本' !important;
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value='1']::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='1']::before {
  content: '标题1' !important;
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value='2']::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='2']::before {
  content: '标题2' !important;
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value='3']::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='3']::before {
  content: '标题3' !important;
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value='4']::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='4']::before {
  content: '标题4' !important;
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value='5']::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='5']::before {
  content: '标题5' !important;
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value='6']::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='6']::before {
  content: '标题6' !important;
}

.ql-snow .ql-picker.ql-font .ql-picker-label::before,
.ql-snow .ql-picker.ql-font .ql-picker-item::before {
  content: '标准字体' !important;
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='serif']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='serif']::before {
  content: '衬线字体' !important;
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='monospace']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='monospace']::before {
  content: '等宽字体' !important;
}
/*  */

.editor {
  position: relative;
}
.editor .ql-container {
  min-height: 800px !important;
}
.answer-html img {
  width: 720px;
  margin: 0 auto;
}
.answer-con .ql-container {
  border: 0 !important;
  padding: 0 !important;
}
/* .input-box .el-textarea__inner{
  line-height: 1;
} */

.markdown-body h2 {
  border-bottom: 0 !important;
}
.markdown-body a {
  color: #00abc8 !important;
}
.markdown-body img {
  width: 75%;
}
.markdown-body .highlight pre, .markdown-body pre{
  color: #fff;
  background-color: #282c34!important;
}
.custom-block.warning {
  background-color: rgba(255, 229, 100, 0.3);
  border-color: #e7c000;
  color: #6b5900;
}
.custom-block.danger,
.custom-block.tip,
.custom-block.warning {
  padding: 0.1rem 1.5rem;
  border-left-width: 0.5rem;
  border-left-style: solid;
  margin: 1rem 0;
}
.tool-nav .el-dropdown .el-button,
.tool-type {
  width: 208px !important;
}
.highlight {
  color: #00abc8;
  text-decoration: underline;
}
</style>
