import {
  createStore
} from 'vuex'
import app from './modules/app'
import faq from './modules/faqList'


import getters from './getters'

import createPersistedState from 'vuex-persistedstate'


export default createStore({
  state: {
    nowModelData: [{
      name: '文件名.las',
      size: '10.00MB',
      format: 'LAS',
      modelType: '激光扫描',
      date: '2017-1-31  23:12:00',
    }],
    // 当前选中列表模型
    nowSelectModel: {},
    isRename: false,
    handleType: '',
    themeColor: "rgba(0, 171, 200)"
  },
  getters,
  mutations: {
    changModelData(state, value) {
      state.nowModelData = value
    },
    changeNowSelectModel(state, value) {
      state.nowSelectModel = value
    },
    changeIsRename(state, value) {
      state.isRename = value
    },
    changeHandleType(state, value) {
      state.handleType = value
    },
    // 改变主题色
    changeThemeColor(state, value) {
      state.themeColor = value;
    }
  },
  actions: {
    deleteNowSelectModel({
      commit,
      state
    }) {
      return new Promise((resolve, reject) => {
        let formdata = new FormData();
        let idArray = []
        for (const key in state.nowSelectModel) {
          idArray.push(state.nowSelectModel[key].id)
        }
        formdata.append('file_id', idArray)
        deleteModel(formdata)
          .then((res) => {
            messageInf(res)
            state.nowSelectModel = [];
            resolve()
          })
          .catch((err) => {
            reject(err)
          })
      })
    }
  },
  modules: {
    app,
    faq
  },
  plugins: [
  // createPersistedState({
  //   reducer(val) {
  //     return {
  //       // 选择储存
  //       token: val.app.token,
  //       // nowShowType: val.service.nowShowType,
  //       // nowOrganization: val.organization.nowOrganization,
  //     }
  //   }
  // })
] //会自动保存创建的状态。刷新还在
})