import request from './request'
//正式地址
//获取后台配置信息
export const siteConfig = () => {
  return request({
    url:developerConfig.userCenterUrl +'/api/ucloud/site_config',
    method: 'GET',
  })
}
//获取微信扫描状态
export const scanState = (params) => {
  return request({
    url:developerConfig.userCenterUrl +'/api/wechat/ping',
    method: 'GET',
    params
  })
}
export const login = (data) => {
  return request({
    url:developerConfig.userCenterUrl +'/api/user/login',
    method: 'POST',
    data
  })
}
//微信扫描
export const scanWX = (data) => {
  return request({
    url:developerConfig.userCenterUrl + '/api/user/scan_wx',
    method: 'POST',
    data
  })
}
//微信登录
export const scanWXLogin = (data) => {
  return request({
    url:developerConfig.userCenterUrl +'/api/user/scan_wx_login',
    method: 'POST',
    data
  })
}
//微信二维码
export const weChatCode = (data) => {
  return request({
    url:developerConfig.userCenterUrl + '/api/wechat/qr_ticket',
    method: 'POST',
    data
  })
}
//手机验证码登录
export const phoneLogin = (data) => {
  return request({
    url:developerConfig.userCenterUrl + '/api/user/mobilelogin',
    method: 'POST',
    data
  })
}
// 注册
export const register = (data) => {
  return request({
    url:developerConfig.userCenterUrl + '/api/user/register',
    method: 'POST',
    data
  })
}

// 发邮箱验证码
export const sentPostboxCode = (data) => {
  return request({
    url:developerConfig.userCenterUrl + '/api/ems/send',
    method: 'POST',
    data
  })
}

// 发手机验证码
export const sentPhoneCode = (data) => {
  return request({
    url:developerConfig.userCenterUrl +'/api/sms/send',
    method: 'POST',
    data
  })
}

// 重置密码
export const resetPassword = (data) => {
  return request({
    url:developerConfig.userCenterUrl +'/api/user/resetpwd',
    method: 'POST',
    data
  })
}

// 设置密码
export const setPassword = (data) => {
  return request({
    url:developerConfig.userCenterUrl +'/api/user/setpwd',
    method: 'POST',
    data
  })
}

//获取用户信息
export const getUserInf = (data) => {
  return request({
    url:developerConfig.userCenterUrl +'/api/ucloud/get_userinfo',
    method: 'POST',
    data
  })
}

// 修改用户信息
export const changeUserInf = (data) => {
  return request({
    url:developerConfig.userCenterUrl + '/api/user/profile',
    method: 'POST',
    data
  })
}

// 修改密码
export const changeUserPasssword = (data) => {
  return request({
    url:developerConfig.userCenterUrl + '/api/user/changepwd',
    method: 'POST',
    data
  })
}

// 修改邮箱
export const changeUserPost = (data) => {
  return request({
    url:developerConfig.userCenterUrl + '/api/user/changeemail',
    method: 'POST',
    data
  })
}

// 修改手机
export const changeUserMobile = (data) => {
  return request({
    url:developerConfig.userCenterUrl +'/api/user/changemobile',
    method: 'POST',
    data
  })
}

// 绑定微信
export const bindWeixin = (data) => {
  return request({
    url:developerConfig.userCenterUrl + '/api/user/bind_wx',
    method: 'POST',
    data
  })
}

// 绑定微信
export const getImgVerify = (data) => {
  return request({
    url:developerConfig.userCenterUrl +'/api/ucloud/img_verify',
    method: 'POST',
    data
  })
}