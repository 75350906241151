<template>
  <div class="video-content">
    <div class="ufjsb">
      <div class="video-menu">
        <div
          class="video-list-item"
          v-for="item in menuList"
          :key="item.id"
          @click="activeTheBtn(item, strId)"
          :class="[item.id == selectMapValue ? 'active' : '']"
        >
          <input
            class="video-list-radio"
            type="radio"
            :id="item.id"
            name="selectMap"
            :checked="item.id == selectMapValue"
          />
          <label class="label-item" :for="item.id">
            <span class="radio-label-txt">{{ item.name }}</span>
          </label>
        </div>
      </div>
      <div class="search-box">
        <el-input
          v-model="searchKey"
          size="large"
          placeholder="请输入相关视频文字"
          @keypress.enter="cataList"
          @blur="cataList"
          @input="
            (e) => {
              e === '' && cataList()
            }
          "
        >
          <template #prefix>
            <el-icon class="el-input__icon"><search /></el-icon>
          </template>
        </el-input>
      </div>
    </div>
    <div class="video-list">
      <div class="video-list-h u-f-jsb">
        <div>
          共<span>{{ videoLearnList.total_record }}</span
          >个
        </div>
        <div></div>
      </div>
      <div class="video-list-c">
        <el-row class="row-bg" :gutter="20">
          <el-col
            :span="6"
            style="margin-bottom: 15px"
            v-for="(item, key) in videoLearnList.list"
            :key="key"
          >
            <div class="case-box">
              <div class="case-box-t">
                <img :src="item.cover" alt="" />
                <svg
                  class="video-icon"
                  @click="openVideoChange(item)"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_499_2510)">
                    <path
                      d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z"
                      fill="white"
                      fill-opacity="0.2"
                    />
                    <path
                      d="M23.7697 16.4399L12.8039 23.9115C12.726 23.9644 12.6355 23.9948 12.542 23.9994C12.4486 24.004 12.3556 23.9825 12.2731 23.9374C12.1906 23.8923 12.1217 23.8251 12.0736 23.7431C12.0255 23.661 12 23.5672 12 23.4716V8.52843C12 8.43279 12.0255 8.33896 12.0736 8.25693C12.1217 8.1749 12.1906 8.10774 12.2731 8.0626C12.3556 8.01746 12.4486 7.99603 12.542 8.0006C12.6355 8.00517 12.726 8.03557 12.8039 8.08855L23.7697 15.5601C23.8405 15.6084 23.8986 15.6738 23.9388 15.7506C23.979 15.8273 24 15.913 24 16C24 16.087 23.979 16.1727 23.9388 16.2494C23.8986 16.3262 23.8405 16.3916 23.7697 16.4399Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_499_2510">
                      <rect width="32" height="32" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div class="case-box-b">
                <h5>{{ item.title }}</h5>
                <p class="u-f-jsb"><s>udStream从入门到精通</s><s>免费</s></p>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-config-provider :locale="locale">
          <el-pagination
            :page-size="Number(videoLearnList.pagesize)"
            :pager-count="5"
            layout="prev, pager, next"
            :total="videoLearnList.total_record"
            @current-change="currentChangeFn"
          />
        </el-config-provider>
      </div>
    </div>
    <!-- 视频播放 -->
    <el-dialog
      v-model="videoList.dialogVisible"
      width="70%"
      style="height: 918px"
      :before-close="handleClose"
    >
      <video
        ref="player"
        style="width: 100%; height: 810px; object-fit: cover"
        controls
        autoplay
        @opened="openVideoChange"
        :src="videoList.videoHref"
      ></video>
      <el-row justify="space-between" class="v-description">
        <el-col :span="20">
          <h1>udStream从入门到精通</h1>
          <p>{{ videoList.introduce }}</p>
        </el-col>
        <el-col
          :span="4"
          style="display: flex; align-items: center; justify-content: right"
        >
          <el-button
            @click="copyLink(videoList.videoHref)"
            type="primary"
            style="width: 60%"
          >
            分享链接
          </el-button>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>
<script>
import { computed, created, nextTick } from 'vue'
import zhCn from 'element-plus/lib/locale/lang/zh-cn'
import { cmsCataList, cmsList, cmsDetails } from '@/utils/contentManage'
let allBtnIndex = [0, 1, 2, 3]
export default {
  name: 'videoTutorial',
  components: {},
  props: {
    //对传过来的值类型进行限制
    masterList: {
      type: Array,
      default: () => [],
    },
    videoId: {
      type: Number,
      default: true,
    },
  },

  data() {
    return {
      listCurrent: 1,
      listPageSize: 16,
      listTotal: 0,
      key: 0,
      show: true,
      menuList: [],
      activeShow: 0,
      inputText: '',
      locale: zhCn,
      n: 1,
      videoList: {
        dialogVisible: false,
        videoHref: '',
        id: 0,
        introduce: '',
      },
      videoLearnList: {
        pagesize: 20,
        total_record: 0,
        list: [],
      },
      menuText: '全部类型',
      filterList: [],
      secondaryId: 0,
      cataListD: [],
      cataListD2: [],
      strId: '',
      selectMapValue: '', //被选中的按钮
      searchKey: '',
    }
  },
  methods: {
    isCatheActived() {
      if (localStorage.getItem('qindex') !== null) {
        const activeIndexBtn = localStorage.getItem('qindex')
        console.log('---', allBtnIndex, activeIndexBtn)
      }
    },
    activeTheBtn(item, strId) {
      localStorage.setItem('activedBtnIndex', item.id)
      this.selectMapValue = item.id
      if (item.id === this.secondaryId) {
        this.getVideoList(strId, this.listCurrent, this.listPageSize)
      } else {
        this.getVideoList(item.id, this.listCurrent, this.listPageSize)
      }
    },

    async getVideoList(videoId, pageNum, pageSize) {
      let formdata = new FormData()
      formdata.append('cata_id', videoId)
      formdata.append('page', pageNum)
      formdata.append('pagesize', pageSize)
      formdata.append('type', 2)
      if (this.searchKey) {
        formdata.append('keywords', this.searchKey)
      }
      const data = await cmsList(formdata)
      if (data.data.code == 1) {
        this.videoLearnList = data.data.data
        this.videoLearnList.list.sort((a, b) => {
          return a.id - b.id
        })
        this.listTotal = data.data.data.total_page
      }
    },
    async cataList2(id) {
      let formdata = new FormData()
      formdata.append('pid', id)
      const data = await cmsCataList(formdata)
      if (data.data.code == 1) {
        this.cataListD2 = data.data.data.list
        this.strId = data.data.data.list.map((item) => item.id).join(',')
        localStorage.setItem('strId', this.strId)
        var newCataListD = [...this.cataListD, ...this.cataListD2]
        this.menuList = newCataListD
        const activeBtnIndex = localStorage.getItem('activedBtnIndex')
        if (
          !this.cataListD2
            .map((item) => item.id)
            .includes(Number(activeBtnIndex))
        ) {
          this.getVideoList(this.strId, this.listCurrent, this.listPageSize)
        } else {
          this.getVideoList(activeBtnIndex, this.listCurrent, this.listPageSize)
        }
        // if(activeBtnIndex!==this.secondaryId){
        //   this.getVideoList(activeBtnIndex, this.listCurrent, this.listPageSize)
        // }else{
        //   this.getVideoList(this.strId, this.listCurrent, this.listPageSize)
        // }
      }
    },
    async cataList() {
      var id = localStorage.getItem('video-id')
      let formdata = new FormData()
      formdata.append('pid', id)
      const data = await cmsCataList(formdata)
      if (data.data.code == 1) {
        this.cataListD = data.data.data.list
        this.secondaryId = data.data.data.list[0].id
        this.cataList2(this.secondaryId)
        const activeBtnIndex = localStorage.getItem('activedBtnIndex')
        if (activeBtnIndex) {
          this.selectMapValue = activeBtnIndex
        } else {
          this.selectMapValue = this.secondaryId
        }
      }
    },
    openVideoChange(e) {
      this.videoList.dialogVisible = true
      this.videoList.videoHref = e.url
      this.videoList.introduce = e.content
      console.log(e.content)
      nextTick(() => {
        this.$refs.player.play()
        // this.$refs.player.onplaying = (e) => {
        //   console.log(this.$refs.player.src, 'onplaying--------------------')
        // }
        // this.$refs.player.onpause = (e) => {
        //   console.log('pause--------------------')
        // }
        this.$refs.player.onended = (e) => {
          // console.log('end--------------------', this.$refs.player.src)
          this.showBtn = true
          // this.$refs.player.src =
          //   'https://developer.eulee.cn/files/video/udstreamsdkvideo2.mp4'
        }
      })
    },
    handleClose() {
      this.videoList.dialogVisible = false
      this.showBtn = false
      nextTick(() => {
        this.$refs.player.pause()
      })
    },
    // 筛选
    // handleCommand(key) {
    //   //这里定义方法可在选取时触发
    //   this.menuText = key
    //   if (key === '全部类型') {
    //     this.filterList = this.videoLearnList.filter((v, i) => v.type > 0)
    //   }
    //   if (key === '基础类型课程') {
    //     this.filterList = this.videoLearnList.filter((v, i) => v.type == 1)
    //   }
    //   if (key === '进阶类型课程') {
    //     this.filterList = this.videoLearnList.filter((v, i) => v.type == 2)
    //   }
    //   if (key === '高阶类型课程') {
    //     this.filterList = this.videoLearnList.filter((v, i) => v.type == 3)
    //   }
    // },
    currentChangeFn(value) {
      this.listCurrent = value
      this.getVideoList(this.strId, this.listCurrent, this.listPageSize)
    },

    copyLink(shareLink) {
      // 直接构建input
      var input = document.createElement('input')
      // 设置内容
      input.value = shareLink
      // 添加临时实例
      document.body.appendChild(input)
      // 选择实例内容
      input.select()
      // 执行复制
      document.execCommand('Copy')
      // 删除临时实例
      document.body.removeChild(input)
      this.$message({
        message: '复制成功',
        type: 'success',
      })
    },
  },
  computed() {},
  created() {
    this.cataList()
  },
  beforeDestroy() {},
  mounted() {},
  beforeUpdate() {},
  beforeCreate() {},
  unmounted() {
    // localStorage.setItem('activedBtnIndex', '')
  },
}
</script>

<style scoped>
.video-content {
  padding: 38px 64px 0 64px;
}
.video-menu ul li {
  float: left;
  padding: 5px 16px;
  margin-right: 16px;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  background: #fff;
  font-size: 14px;
  color: #606266;
  cursor: pointer;
}
.video-menu ul li.active {
  border-radius: 4px;
  border: 1px solid #00abc8;
  background: #e6ffff;
  color: #00abc8;
}
.search-box {
  width: 25vw;
}
.video-list-h {
  width: 100%;
  height: 48px;
  box-sizing: border-box;
  line-height: 48px;
  color: #606266;
  font-size: 16px;
}
.video-list-h span {
  color: #00abc8;
}
.video-list-c {
  padding: 40px;
}
.video-list-c .case-box {
  border: 0.5px solid #e4e7ed;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}
.video-list-c .case-box-t {
  overflow: hidden;
  width: 100%;
  height: 168px;
  position: relative;
}
.video-icon {
  position: absolute;
  left: 16px;
  bottom: 16px;
  cursor: pointer;
}
.case-box-t img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.video-list-c .case-box-b {
  padding: 18px;
}
.video-list-c .case-box-b h5 {
  font-size: 16px;
  line-height: 24px;
  color: #303133;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.video-list-c .case-box-b p s {
  text-decoration: none;
  font-size: 14px;
  color: #909399;
  line-height: 22px;
  margin-top: 15px;
}
.video-list-c .case-box-t img {
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
}
.video-list-c .case-box-t:hover > img {
  -webkit-transform: scale(1.3);
  -moz-transform: scale(1.3);
  -ms-transform: scale(1.3);
  transform: scale(1.3);
}
.el-pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.video-list-item {
  float: left;
  padding: 5px 16px;
  margin-right: 16px;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  background: #fff;
  font-size: 14px;
  color: #606266;
  cursor: pointer;
}
.video-list-item.active {
  border-radius: 4px;
  border: 1px solid #00abc8;
  background: #e6ffff;
}
.video-list-radio {
  display: none;
}

.video-list-radio + label {
  display: inline-block;
}

.video-list-radio + label :hover {
  cursor: pointer;
}

.video-list-radio:checked + label {
  color: #05a6ca;
}
</style>
