<template>
  <div class="developer-layout APIdocumentation">
    <el-container>
      <Header></Header>
      <el-main>
        <div class="nav-big-box u-f-jsb" :class="{ sticky: isShow }">
          <div class="tool-nav u-f-ac">
            <el-dropdown popper-class="tool-type" @command="dChangeValue">
              <el-button size="large" type="primary">
                {{ selectValue }}
                <el-icon class="el-icon--right"><arrow-down /></el-icon>
              </el-button>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item
                    v-for="item in toolsOption"
                    :key="item.id"
                    :command="item.id"
                    >{{ item.name }}</el-dropdown-item
                  >
                </el-dropdown-menu>
              </template>
            </el-dropdown>
            <ul>
              <li
                :class="{
                  active: active === item.id,
                }"
                v-for="(item, index) in toolsList"
                :key="item.name"
                @click="changeTopNav2(item)"
              >
                {{ item.name }}
              </li>
            </ul>
          </div>
          <div>
            <div class="u-f-ac nav-right">
              <div class="g-time" v-if="selectValue === 'udStream SDK'">
                <span>最新版本：V1.0.0</span>
                <span>更新时间：2023.1.13</span>
              </div>
              <div class="g-time" v-if="selectValue === 'udSDK'">
                <span>最新版本：V1.0.0</span>
                <span>更新时间：2023.1.12</span>
              </div>
              <div class="g-time" v-if="selectValue === 'udUnity SDK'">
                <span>最新版本：V1.0.0</span>
                <span>更新时间：2023.2.11</span>
              </div>
              <div class="g-time" v-if="selectValue === 'udUE SDK'">
                <span>最新版本：V1.0.0</span>
                <span>更新时间：2022.01.20</span>
              </div>
              <div class="g-time" v-if="selectValue === 'Unity Toolkit'">
                <span>最新版本：V1.0.0</span>
                <span>更新时间：2023.02.14</span>
              </div>
              <div class="g-time" v-if="selectValue === 'UE Toolkit'">
                <span>最新版本：V1.0.0</span>
                <span>更新时间：2022.02.21</span>
              </div>
              <div v-if="selectValue === 'udStream SDK'">
                <a
                  href="https://developer.eulee.cn/download/udStreamSDK_V1.0.0.zip"
                  ><el-button size="large" plain
                    ><img
                      src="../../../public/icon/vue-icon.svg"
                      alt=""
                    />下载Vue版</el-button
                  ></a
                >
                <a
                  href="https://developer.eulee.cn/download/udStreamSDKjs_V1.0.0.zip"
                  ><el-button size="large" plain
                    ><img
                      src="../../../public/icon/js-icon.svg"
                      alt=""
                    />下载原生JS版</el-button
                  ></a
                >
              </div>
              <div v-else>
                <a
                  v-if="selectValue === 'udSDK'"
                  href="https://developer.eulee.cn/download/udSDK_V1.0.0.zip"
                >
                  <el-button size="large" plain
                    ><img
                      src="../../../public/icon/xzkfb-icon.svg"
                      alt=""
                    />下载开发包</el-button
                  ></a
                >
                <a
                  v-if="selectValue === 'udUnity SDK'"
                  href="https://developer.eulee.cn/download/udUnitySDK_V1.0.0.zip"
                >
                  <el-button size="large" plain
                    ><img
                      src="../../../public/icon/xzkfb-icon.svg"
                      alt=""
                    />下载开发包</el-button
                  ></a
                >
                <a
                  v-if="selectValue === 'udUE SDK'"
                  href="https://developer.eulee.cn/download/udUESDK_V1.0.0.zip"
                >
                  <el-button size="large" plain
                    ><img
                      src="../../../public/icon/xzkfb-icon.svg"
                      alt=""
                    />下载开发包</el-button
                  ></a
                >
                <a
                  v-if="selectValue === 'Unity Toolkit'"
                  href="https://developer.eulee.cn/download/UnityToolkit_V1.0.0.zip"
                >
                  <el-button size="large" plain
                    ><img
                      src="../../../public/icon/xzkfb-icon.svg"
                      alt=""
                    />下载开发包</el-button
                  ></a
                >
                <a
                  v-if="selectValue === 'UE Toolkit'"
                  href="https://developer.eulee.cn/download/UEToolkit_V1.0.0.zip"
                >
                  <el-button size="large" plain
                    ><img
                      src="../../../public/icon/xzkfb-icon.svg"
                      alt=""
                    />下载开发包</el-button
                  ></a
                >
                <div v-if="selectValue === 'udCesium'">
                  <a
                  href="https://developer.eulee.cn/download/SDK_before_cesium1.101.zip"
                >
                  <el-button size="large" plain
                    ><img
                      src="../../../public/icon/xzkfb-icon.svg"
                      alt=""
                    />cesium1.1之前的版本</el-button
                  ></a
                >
                <a
                  href="https://developer.eulee.cn/download/SDK_later_cesium1.102.zip"
                >
                  <el-button size="large" plain
                    ><img
                      src="../../../public/icon/xzkfb-icon.svg"
                      alt=""
                    />cesium1.1之后的版本</el-button
                  ></a
                >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="isShow" style="height: 72px"></div>
        <div class="main-content">
          <el-container>
            <el-aside v-if="showD" width="272px" :class="{ sticky: isShow }">
              <div class="sort-con-title">
                <div
                  class="sort-list"
                  v-for="item in categoryList"
                  :key="item.id"
                >
                  <h1>{{ item.sort }}</h1>
                  <ul>
                    <li
                      v-for="subitem in item.sortList"
                      @click="toDetail(subitem.id)"
                      :class="{
                        active: subitem.id == $route.params.id,
                      }"
                      :key="subitem.id"
                    >
                      {{ subitem.title }}
                    </li>
                  </ul>
                  <el-divider />
                </div>
              </div>
            </el-aside>
            <el-main :class="{ ml272: isShow }">
              <!-- <keep-alive> -->
              <router-view></router-view>
              <!-- </keep-alive> -->
            </el-main>
          </el-container>
        </div>
      </el-main>
      <el-footer style="height:0!important"> </el-footer>
    </el-container>
  </div>
</template>
<script setup>
import {
  markRaw,
  reactive,
  ref,
  shallowRef,
  onUnmounted,
  onMounted,
  watch,
  nextTick,
  computed,
} from 'vue'
import Header from '@/components/layouts/Header'
import { cmsCataList, cmsList, cmsDetails } from '@/utils/contentManage'
import { useRouter, useRoute } from 'vue-router'

import { marked } from 'marked'
import 'github-markdown-css/github-markdown.css'

const toolsList = ref([])
const categoryList = ref([])
const isShow = ref(false)
const active = ref(0)
const showVideoBtn = ref(false)

const router = useRouter()
const route = useRoute()
const conShow = computed(() => route.name === 'Video')
const selectValue = ref('udStream SDK')
const toolsOption = ref([])
const selectId = ref(0)
const dropdownId = ref(0)
const showD = ref(false)
const asideList = ref([])
const isChangeD = ref(false)
const handleScroll = () => {
  const top = document.documentElement.scrollTop
  isShow.value = top > 64
}
window.addEventListener('scroll', handleScroll)

// 跳转到文章详情
const toDetail = (id) => {
  router.push('/DevelopmentTool/contentDetailTool/' + id)
}

const dChangeValue = (value) => {
  isChangeD.value = false
  changeValue(value)
}

const changeValue = (value) => {
  changeNavData(value)
  dropdownId.value = value
  localStorage.dropdownId = value
  toolsOption.value.forEach(function (item) {
    if (item.id === value) {
      selectValue.value = item.name
    }
  })
}

const changeTopNav2 = (item) => {
  if (item.name != '开发示例') {
    showD.value = true
    router.push('/DevelopmentTool')
  } else {
    showD.value = false
     //if(localStorage.dropdownId==='26'||localStorage.activeTop==='55'){
    if(localStorage.dropdownId==='29'||localStorage.activeTop==='73'){
     var caseLink = 'https://demo.eulee.cn/example/'
      //router.push({ path: '/DevelopmentTool/CaseLink', params: { caseLink }})
     router.push('/DevelopmentTool/CaseLink?caseLink=' + caseLink)
     //localStorage.setItem("src",caseLink)
      //router.push('/DevelopmentTool/CaseLink')
    }else{
      var caseLink = 'https://demo.eulee.cn/example/udWebGL.html'
      //router.push({ path: '/DevelopmentTool/CaseLink', params: { caseLink }})
     router.push('/DevelopmentTool/CaseLink?caseLink=' + caseLink)
     //localStorage.setItem("src",caseLink)
    }
    
  }
  active.value = item.id
  getAsideData(active.value)
  localStorage.activeTop = item.id
  localStorage.activeName = item.name
}

// 修改顶部菜单选种项
const changeTopNav = (item) => {
  if (item.name != '开发示例') {
    showD.value = true
    console.log(isChangeD.value)
    router.push(
      route.params.id && isChangeD.value
        ? '/DevelopmentTool/contentDetailTool/' + route.params.id
        : '/DevelopmentTool'
    )
  } else {
    showD.value = false
   // router.push('/DevelopmentTool/CaseLink')
  }
  active.value = item.id
  getAsideData(active.value)
  localStorage.activeTop = item.id
  localStorage.activeName = item.name
}

// 获取顶部导航栏的数据
const getLeftData = async () => {
  let formdata = new FormData()
  formdata.append('pid', 0)
  const {
    data: { data, code },
  } = await cmsCataList(formdata)
  data.list.forEach(function (item) {
    if (item.name === '开发工具') {
      selectId.value = item.id
    }
  })
  selectData()
}
const selectData = async () => {
  let formdata = new FormData()
  formdata.append('pid', selectId.value)
  const {
    data: { data, code },
  } = await cmsCataList(formdata)
  toolsOption.value = data.list
  const temp = selectValue.value
  selectValue.value =
    toolsOption.value.find(
      (item) => item.id === Number(localStorage.dropdownId)
    )?.name || temp
  //初始化nav的数据
  var initId = localStorage.dropdownId || toolsOption.value[0].id
  navData(initId)
}

const changeNavData = async (id) => {
  let formdata = new FormData()
  formdata.append('pid', id)
  const {
    data: { data, code },
  } = await cmsCataList(formdata)
  toolsList.value = data.list
  changeTopNav(
    toolsList.value.find((i) => i.id === active.value) || toolsList.value?.[0]
  )
}

const navData = async (id) => {
  let formdata = new FormData()
  formdata.append('pid', id)
  const {
    data: { data, code },
  } = await cmsCataList(formdata)
  toolsList.value = data.list
  active.value = parseInt(localStorage.activeTop) || toolsList.value?.at(0)?.id
  // getLeftTitleData(active.value)
  getAsideData(active.value)
}
// 获取侧边栏的数据
const getAsideData = async (id) => {
  var ele = document.getElementsByClassName('developer-layout')
  ele[0].style.height = ''
  let formdata = new FormData()
  formdata.append('pid', id)
  const {
    data: { data, code },
  } = await cmsCataList(formdata)
  asideList.value = data.list
  // console.log(route.fullPath)
  categoryList.value = await getArticleData(asideList.value)
  showD.value = categoryList.value.length > 0
  if (route.fullPath != '/DevelopmentTool/CaseLink?caseLink=https://demo.eulee.cn/example/'&&route.fullPath!='/DevelopmentTool/CaseLink?caseLink=https://demo.eulee.cn/example/udWebGL.html') {
    const currentId =
      route.params.id ?? categoryList.value.at(0).sortList.at(0).id
    nextTick(() => {
      router.replace('/DevelopmentTool/contentDetailTool/' + currentId)
    })
  } else {
    ele[0].style.height = '100%'
  }
}

// 获取所有文章相关的标题数据
const getArticleData = async (newList) => {
  const newListResult = await Promise.all(
    newList.map((item) => {
      const formData = new FormData()
      formData.append('cata_id', item.id)
      formData.append('page', 1)
      formData.append('pagesize', 10000)
      formData.append('type', 0)
      return cmsList(formData)
    })
  )
  // console.log(newListResult)
  return newListResult
    .map((item) => item.data.data.list.sort((a, b) => a.id - b.id))
    .map((item) => ({
      id: newList.find((i) => i.id === item?.[0]?.cata_id)?.id,
      sort: newList.find((i) => i.id === item?.[0]?.cata_id)?.name,
      sortList: item,
    }))
}

// 初始化
const initData = async () => {
  await getLeftData()
  active.value = parseInt(localStorage.activeTop) || toolsList.value?.at(0)?.id
  isChangeD.value = true
  if (localStorage.dropdownId) {
    dropdownId.value = Number(localStorage.dropdownId)
    changeValue(dropdownId.value)
  }
  // if (localStorage.activeName === '开发示例') {
  //   showD.value = false
  //   localStorage.setItem('activeTop', 55)
  // }
}
onMounted(() => {
  initData()
})
onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll)
  localStorage.activeTop = null
})
</script>

<style scoped>
.ml272 {
  margin-left: 272px;
}
/* .developer-layout {
  height: 100%;
} */
.nav-big-box {
  height: 72px;
  background: #fff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.08),
    0px 6px 16px 4px rgba(0, 0, 0, 0.04);
  padding-left: 32px;
  padding-right: 32px;
}
.nav-right .g-time {
  margin-right: 28px;
}
.nav-right .g-time span {
  color: #909399;
  font-size: 14px;
  margin-right: 8px;
}
.nav-right a:nth-child(1) {
  margin-right: 15px;
}
.nav-right .el-button img {
  margin-right: 8px;
}
.tool-nav ul {
  display: flex;
  align-items: center;
  padding-left: 50px;
}
.tool-nav ul li {
  float: left;
  padding: 9px 20px;
  margin-right: 12px;
  font-size: 14px;
  color: #606266;
  cursor: pointer;
}
.tool-nav .active {
  background: #f0f2f5;
  color: #00abc8;
}
.nav-big-box.sticky {
  position: fixed;
  width: 100vw;
  box-sizing: border-box;
  left: 0;
  top: 0;
  z-index: 2000;
  transition: 0.3s ease-in;
}
.video-course.active {
  border-radius: 4px;
  border: 1px solid #41b883;
  background: #41b883;
  color: #fff;
}
.el-aside.sticky {
  position: fixed;
  /* width: 100vw; */
  /* box-sizing: border-box; */
  left: 0;
  top: 72px;
  z-index: 2000;
  transition: 0.3s ease-in;
}
.main-content,
.el-container {
  width: 100%;
  height: 100%;
  position: relative;
}
.main-content .el-aside {
  padding: 32px 8px 0 32px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: auto;
  border-right: 1px solid #e4e7ed;
  height: calc(100vh - 72px);
}
.main-content .el-aside .el-button.is-plain {
  width: 208px;
  box-sizing: border-box;
  height: 40px;
  font-size: 14px;
}
.sort-con-title {
  height: calc(100vh - 150px);
  overflow-y: scroll;
  padding-right: 16px;
}
.sort-con-title::-webkit-scrollbar {
  width: 4px;
}
.sort-con-title::-webkit-scrollbar-thumb {
  background-color: #e6e6e6;
  border-radius: 4px;
}
.sort-list ul li {
  cursor: pointer;
  width: 100%;
  padding: 9px 16px 9px 16px;
  padding-left: 16px;
  box-sizing: border-box;
}
.sort-list ul li.active {
  border-radius: 8px;
  background: #e6ffff;
  color: #00abc8;
}
.sort-list h1 {
  font-weight: 600;
  margin-top: 32px;
  margin-bottom: 8px;
  font-size: 16px;
  color: #303133;
}
.el-dropdown .el-icon {
  margin-left: 64px;
}

.el-footer {
  height: 0;
}
</style>
