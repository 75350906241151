import {
  createRouter,
  createWebHashHistory
} from 'vue-router'
import Home from '@/views/Home.vue'
import DeveloperCommunity from '@/views/developerCommunity/DeveloperCommunity.vue'
import TechnicalSupport from '@/views/technicalSupport/index.vue'
import DevelopmentTool from '@/views/developmentTool/index.vue'
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/DeveloperCommunity',
    name: 'DeveloperCommunity',
    component: DeveloperCommunity,
    redirect: '/DeveloperCommunity/MainDeveloperCommunity',
    children: [{
      path: 'MainDeveloperCommunity',
      name: 'MainDeveloperCommunity',
      component: () => import('@/views/developerCommunity/MainDeveloperCommunity.vue')
    },
    {
      path: 'DetailsDeveloperCommunity',
      name: 'DetailsDeveloperCommunity',
      component: () => import('@/views/developerCommunity/DetailsDeveloperCommunity.vue')
    }
    ]
  },
  {
    path: '/TechnicalSupport',
    name: 'TechnicalSupport',
    component: TechnicalSupport,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'content/:id',
        name: 'contentDetail',
        component: () => import('@/views/technicalSupport/content.vue')
      },
      {
        path:'video',
        name: 'Video',
        component: () => import('@/views/technicalSupport/video.vue')
      }
    ]
  },
  {
    path: '/DevelopmentTool',
    name: 'DevelopmentTool',
    component: DevelopmentTool,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'contentDetailTool/:id',
        name: 'contentDetailTool',
        component: () => import('@/views/developmentTool/content.vue')
      },
      {
        path:'CaseLink',
        name: 'CaseLink',
        component: () => import('@/views/developmentTool/caseLink.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})


export default router