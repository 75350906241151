import {
  login as loginApi,
  scanWX as scanWXApi,
  bindWeixin as bindWeixinApi,
  scanWXLogin as scanWXLoginApi,
  phoneLogin as phoneLoginApi
} from '@/utils/login'

import router from '@/router'
import {
  setTokenTime
} from '@/utils/auth'

import {
  messageInf
} from '@/utils/message'
import cookies from 'vue-cookies' //引入cookie

export default {
  namespaced: true,
  state: () => ({
    token: '',
    // 当前用户信息
    userData: {},
    // 当前nowFileId文件夹下的文件目录
    modelDataList: [],
    // 当前选中的文件id
    nowFileId: 0,
    // 当前面包屑渲染列表
    nowFileList: [],
    // 清空选中
    clearAll: 0,
    //上传成功模型的url
    uploadModelUrl: '',
    //后台配置
    siteConfig: {},
    nowSourceType: 0,
    strScence: ''
  }),
  mutations: {
    setToken(state, token) {
      state.token = token
      //localStorage.removeItem('token')
      //localStorage.setItem('token', token)
    },
    setInfo(state, data) {
      state.userData = data
      localStorage.setItem('userData', JSON.stringify(data))
    },
    setModelDataList(state, data) {
      state.modelDataList = data
    },
    resetUserName(state, data) {
      state.userData.username = data
      localStorage.setItem('userData', JSON.stringify(state.userData))
    },
    resetUserEmail(state, data) {
      state.userData.email = data
      localStorage.setItem('userData', JSON.stringify(state.userData))
    },
    resetUserMobile(state, data) {
      state.userData.mobile = data
      localStorage.setItem('userData', JSON.stringify(state.userData))
    },
    resetUserV(state, data) {
      state.userData.avatar = data;
      localStorage.setItem('userData', JSON.stringify(state.userData))
    },
    setNowFileId(state, data) {
      state.nowFileId = data
    },
    setNowFileList(state, data) {
      if (data === 'zxc') {
        state.nowFileList = []
      } else {
        state.nowFileList.push(data)
      }
    },
    setNowFileList2(state, data) {
      state.nowFileList = data
    },
    changeClearAll(state) {
      state.clearAll = state.clearAll + 1
    },
    setUploadModelUrl(state, url) {
      state.uploadModelUrl = url
    },
    setSiteConfig(state, data) {
      state.siteConfig = data
    },
    setNowSourceType(state, data) {
      state.nowSourceType = data
    },
    setStrScence(state, data) {
      state.strScence = data
    },
  },
  actions: {
    login({
      commit,
      state
    }, user) {
      return new Promise((resolve, reject) => {
        let formdata = new FormData();
        formdata.append("account", user.account);
        formdata.append("password", user.password);
        if (user.str_scence) {
          formdata.append("str_scence", user.str_scence);
        }
        loginApi(formdata)
          .then((res) => {
            commit('setToken', res.data.data.userinfo.token)
            commit('setInfo', res.data.data.userinfo)
            setTokenTime()
            if (res.data.data.userinfo.prevtime) {
              if (localStorage.getItem('share_link')) {
                let link_id = localStorage.getItem('share_link');
                router.replace('/ResourceShare?share_link=' + link_id)
              } else {
                router.replace('/')
              }
              resolve()
            } else {
              router.replace('/entryPage')
              resolve()
            }
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    scanWX({
      commit,
      dispatch,
      state
    }, code) {
      return new Promise((resolve, reject) => {
        let formdata = new FormData();
        formdata.append("code", code);
        scanWXApi(formdata)
          .then((res) => {
            let is_register = res.data.data.is_register;
            if (is_register == 0) {
              localStorage.setItem('wxData', JSON.stringify(res.data.data))
              router.replace('/Login/BindAccount')
            } else {
              dispatch('wxLogin', res.data.data);
            }
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    wxLogin({
      commit,
      state
    }) {
      return new Promise((resolve, reject) => {
        let formdata = new FormData();
        formdata.append("str_scence", state.strScence);
        if (localStorage.getItem('invit_code')) {
          formdata.append("group_invite_code", localStorage.getItem('invit_code'));
        }
        scanWXLoginApi(formdata)
          .then((res) => {
            commit('setToken', res.data.data.userinfo.token)
            commit('setInfo', res.data.data.userinfo)
            setTokenTime()
            if (res.data.data.userinfo.prevtime) {
              if (localStorage.getItem('share_link')) {
                let link_id = localStorage.getItem('share_link');
                router.replace('/ResourceShare?share_link=' + link_id)
              } else {
                router.replace('/')
              }
              resolve()
            } else {
              router.replace('/entryPage')
              resolve()
            }
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    phoneLogin({
      commit,
      state
    }, user) {
      return new Promise((resolve, reject) => {
        phoneLoginApi(user)
          .then((res) => {
            commit('setToken', res.data.data.userinfo.token)
            commit('setInfo', res.data.data.userinfo)
            setTokenTime()
            if (res.data.data.userinfo.prevtime) {
              if (localStorage.getItem('share_link')) {
                let link_id = localStorage.getItem('share_link');
                router.replace('/ResourceShare?share_link=' + link_id)
              } else {
                router.replace('/')
              }
              resolve()
            } else {
              router.replace('/entryPage')
              resolve()
            }
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    bindWeixin({
      commit,
      state
    }, code) {
      return new Promise((resolve, reject) => {
        let formdata = new FormData();
        formdata.append("code", code);
        bindWeixinApi(formdata)
          .then((res) => {
            messageInf(res)
            router.replace('/PersonalCenter')
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    // 退出
    logout({
      commit
    }) {
      commit('setToken', '')
      localStorage.clear()
      //清除cookies
      function deleteCookieWithDomain(name, domain) {
        var cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        if (domain) {
          cookie += " domain=" + domain;
        }
        document.cookie = cookie;
      }
      deleteCookieWithDomain("token", developerConfig.cookieDomain);
      var nHref = window.location.href
      if (nHref.indexOf("#") != -1) {
        nHref = nHref.split("#")[0]
      }
      window.open(developerConfig.ssoPageUrl + '?target=' + nHref, '_self') //下周更新
    },
    // 获取列表数据
    getModelList({
      commit,
      state
    }, listId) {
      if (listId) {
        return new Promise((resolve, reject) => {
          getModelListApi(listId)
            .then((res) => {
              commit('setModelDataList', res.data.data.list)
              resolve()
            })
            .catch((err) => {
              reject(err)
            })
        })
      } else {
        return new Promise((resolve, reject) => {
          let formdata2 = new FormData();
          formdata2.append("page", 1);
          formdata2.append("pagesize", 2000);
          formdata2.append("parent_id", state.nowFileId);
          formdata2.append("ftype", state.nowSourceType);
          getModelListApi(formdata2)
            .then((res) => {
              commit('setModelDataList', res.data.data.list)
              resolve()
            })
            .catch((err) => {
              reject(err)
            })
        })
      }
    },
    // 更新列表

  }
}