import request from './request'
// cms分类列表
export const cmsCataList = (data) => {
  return request({
      url: '/api/faq/cms_cata_list',
      method: 'POST',
      data
  })
}
// cms内容列表
export const cmsList = (data) => {
  return request({
      url: '/api/faq/cms_list',
      method: 'POST',
      data
  })
}
// cms内容
export const cmsDetails = (data) => {
  return request({
      url: '/api/faq/cms_details',
      method: 'POST',
      data
  })
}
