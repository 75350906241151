import request from './request'
// 开发者社区提问
export const communityCommitQuestion = (data) => {
  return request({
      url: '/api/faq/commit_question',
      method: 'POST',
      data
  })
}
/**  获取问题列表  */

export const queryFaqList = (data) => {
  return request({
    url: '/api/faq/faq_list',
    method: 'POST',
    data
  })
}

/**  问题详情  */

export const queryFaqDetails = (data) => {
  return request({
    url: '/api/faq/faq_details',
    method: 'POST',
    data
  })
}
/**  问题回答  */

export const answerQuestion = (data) => {
  return request({
      url: '/api/faq/answer_question',
      method: 'POST',
      data
  })
}
// 上传文件
export const imgUpload = (data) => {
  return request({
      url: '/api/faq/upload',
      method: 'POST',
      data
  })
}

// 发表评论

export const addComment = (data) => {
  return request({
      url: '/api/faq/add_comment',
      method: 'POST',
      data
  })
}

// 评论列表/api/faq/comment_list

export const commentList = (data) => {
  return request({
      url: '/api/faq/comment_list',
      method: 'POST',
      data
  })
}

// 删除问题

export const delQuestion = (data) => {
  return request({
      url: '/api/faq/del_question',
      method: 'POST',
      data
  })
}
//编辑提问
export const editQuestion = (data) => {
  return request({
      url: '/api/faq/edit_question',
      method: 'POST',
      data
  })
}