<template>
  <div class="developer-layout APIdocumentation">
    <el-container>
      <Header></Header>
      <el-main>
        <!-- <div style="height: 64px"></div> -->
        <div class="APIdocumentation-bg">
          <div class="w1280 u-f-jsb">
            <h1
              class="wow animate__animated animate__fadeInUp title"
              data-wow-duration="2s"
            >
              开发者社区
            </h1>
            <div
              class="u-f-ac"
              style="width: 60%"
              v-if="routeState === '/DeveloperCommunity/MainDeveloperCommunity'"
            >
              <el-input
                v-model="searchKey"
                class="w-50 m-2 ask-question-search"
                clearable
                placeholder="文章/答案"
                suffix-icon="Search"
                @keypress.enter="seachFaqlList"
                @input="
                  (e) => {
                    e === '' && seachFaqlList()
                  }
                "
              />
              <el-button
                class="ask-question"
                type="primary"
                @click="openAqDialog()"
                >提问</el-button
              >
            </div>
          </div>
        </div>
        <div class="w1280" style="margin-top: 24px">
          <router-view />
        </div>
        <ask-questions
          :dialogVisible="addDialogVisible"
          @close="sendDilog"
        ></ask-questions>
      </el-main>
      <el-footer style="height:276px!important">
        <Footer></Footer>
      </el-footer>
    </el-container>
  </div>
</template>
<script setup>
import {
  onBeforeUnmount,
  onMounted,
  ref,
  watch,
  onUnmounted,
  nextTick,
  onUpdated,
  reactive,
  onBeforeMount,
  computed,
} from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import Header from '@/components/layouts/Header'
import Footer from '@/components/layouts/Footer'
import AskQuestions from '@/components/elDialog/AskQuestions'
const router = useRouter()
const store = useStore()
const isFixed = ref(false)
const searchInput = ref('')
const activeName = ref(localStorage.getItem('report'))
// console.log(activeName.value)
// let vuexFaqList = ref([])
let vuexFaqList = computed(() => store.state.faq.faqList)
const showLoadMore = computed(() => store.state.faq.showLoadMore)
const addDialogVisible = ref(false)
const searchKey = ref('')
const biaoqiantou = reactive([
  {
    id: '',
    name: '全部问答',
  },
  {
    id: '1',
    name: '数据转换',
  },
  {
    id: '2',
    name: '软件使用',
  },
  {
    id: '3',
    name: '开发编码',
  },
])
const routeState = ref('')

const sendDilog = (value) => {
  addDialogVisible.value = value
  console.log(addDialogVisible.value)
  // store.dispatch('faq/getFaqList',{})
}
const getList = async (name) => {
  store.dispatch('faq/getFaqList', {
    tags: name,
  })
}
const handleClick = (tab, event) => {
  store.state.faq.faqList = []
  store.dispatch('faq/getFaqList', {
    page: 1,
    tags: tab.props.name,
  })
  localStorage.setItem('report', tab.props.name)
  localStorage.setItem('activeName', tab.props.label)
  activeName.value = tab.props.name
  // getList(tab.props.name)
}
const loadMore = () => {
  if (store.state.faq.showLoadMore) {
    store.commit('faq/INCREMENT_PAGE_SIZE')
    store.dispatch('faq/getFaqList', {
      tags: localStorage.getItem('report'),
    })
  }
}
const openAqDialog = () => {
  addDialogVisible.value = true
}
//查询问题
const seachFaqlList = () => {
  store.dispatch('faq/getFaqList', {
    keyword: searchKey.value,
  })
}
onMounted(() => {
  routeState.value = router.currentRoute.value.path
  // console.log(routeState.value)
  // getList(localStorage.getItem('report'))
})
onUnmounted(() => {
  // localStorage.setItem('report', '')
})
onUpdated(() => {
  routeState.value = router.currentRoute.value.path
  // console.log(routeState.value)
})
</script>

<style scoped>
.APIdocumentation-bg {
  height: 148px;
  background: url(../../assets/imgs/jszc_bg.png) 0 0 no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
}

.APIdocumentation .title {
  font-size: 36px;
  color: #303133;
  font-weight: normal;
}

.APIdocumentation .title h6 {
  font-weight: normal;
}

.ask-question-search {
  width: 80%;
  height: 40px;
  margin-right: 24px;
}

.ask-question {
  width: 68px;
  height: 40px;
  background: #00abc8;
}

.aq-list {
  margin-top: 24px;
}

.aq-list .title {
  color: #303133;
  font-size: 20px;
  line-height: 32px;
}

.aq-list .title:hover {
  color: #00abc8;
}

.aq-list .introduce {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 14px;
  line-height: 22px;
  color: #606266;
  margin-top: 12px;
  margin-bottom: 24px;
}

.aq-list-ul li {
  color: #909399;
  font-size: 14px;
}

.aq-list-ul li svg {
  margin-right: 5px;
}

.aq-list-ul li:nth-child(1) {
  color: #303133;
  font-size: 14px;
  margin-right: 12px;
}

.aq-list-ul li:nth-child(1) .el-avatar {
  margin-right: 12px;
}

.aq-list-ul li:nth-child(2) {
  margin-right: 25px;
}

.aq-list-ul li:nth-child(3) {
  margin-right: 9px;
}

.el-footer {
  line-height: 32px;
  font-size: 20px;
  height: 0;
}

.el-button.is-link {
  font-size: 16px;
  line-height: 24px;
  margin: 0 auto;
}

.load-more {
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>
