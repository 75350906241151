<template>
  <div
    class="u-f-jsb header"
    :class="{ 'bg-transparent': !showWhiteBackground }"
  >
    <div class="u-f-ac">
      <div style="margin-right: 8%">
        <img src="../../../public/icon/developer-logo.svg" alt="" />
      </div>
      <el-menu
        :default-active="asd"
        class="el-menu-ylcloud"
        mode="horizontal"
        :ellipsis="false"
        router
        @select="handleSelect"
      >
        <template v-if="!showWhiteBackground">
          <el-menu-item index="/">首页</el-menu-item>
          <el-menu-item index="/DevelopmentTool">开发工具</el-menu-item>
          <!-- <el-sub-menu index="2">
            <template #title><div class="kfwd">开发文档</div></template>
            <el-menu-item index="/DevelopmentToolkit">开发指南</el-menu-item>
            <el-menu-item index="/APIdocumentation">API文档</el-menu-item>
            <el-menu-item index="/DevelopmentCase">开发案例</el-menu-item>
          </el-sub-menu> -->
          <!-- <el-sub-menu index="3">
            <template #title><div class="kfwd">技术支持</div></template>
            <el-menu-item index="/TechnicalGuide">技术指南</el-menu-item>
            <el-menu-item index="/TechnicalFAQ">技术FAQ</el-menu-item>
            <el-menu-item index="/TechnicalLearning">技术学习</el-menu-item>
          </el-sub-menu> -->
          <el-menu-item index="/TechnicalSupport">技术支持</el-menu-item>
          <el-menu-item index="/DeveloperCommunity">开发者社区</el-menu-item>
        </template>
        <template v-else>
          <el-menu-item class="bg-color" index="/">首页</el-menu-item>
          <el-menu-item class="bg-color" index="/DevelopmentTool"
            >开发工具</el-menu-item
          >
          <!-- <el-sub-menu index="2">
            <template #title><div class="bg-color">开发文档</div></template>
            <el-menu-item index="/DevelopmentToolkit">开发指南</el-menu-item>
            <el-menu-item index="/APIdocumentation">API文档</el-menu-item>
            <el-menu-item index="/DevelopmentCase">开发案例</el-menu-item>
          </el-sub-menu> -->
          <!-- <el-sub-menu index="3">
            <template #title><div class="bg-color">技术支持</div></template>
            <el-menu-item index="/TechnicalGuide">技术指南</el-menu-item>
            <el-menu-item index="/TechnicalFAQ">技术FAQ</el-menu-item>
            <el-menu-item index="/TechnicalLearning">技术学习</el-menu-item>
          </el-sub-menu> -->
          <el-menu-item class="bg-color" index="/TechnicalSupport"
            >技术支持</el-menu-item
          >
          <el-menu-item class="bg-color" index="/DeveloperCommunity"
            >开发者社区</el-menu-item
          >
        </template>
      </el-menu>
    </div>
    <div class="u-f-ac header-r">
      <user-icon v-if="userLogin"></user-icon>
      <div v-else>
        <el-button color="#00ABC8" @click="login">登录 / 注册</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch, onBeforeUnmount, nextTick, inject } from 'vue'
import UserIcon from '../top/User/UserIcon'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { getUserInf as getUserInfApi } from '@/utils/login.js'
export default {
  name: '',
  components: {
    UserIcon,
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const activeIndex = ref('1')
    const userLogin = ref(false)
    const cookies = ref('')
    let showWhiteBackground = ref(false)
    let asd = ref('')
    asd.value = router.currentRoute.value.matched[0].path
    watch(
      () => router.currentRoute.value.path,
      () => {
        if (router.currentRoute.value.matched.length > 1) {
          asd.value = router.currentRoute.value.matched[0].path
        }
      }
    )

    if (asd.value == '/') {
      document.addEventListener('scroll', function () {
        let bodyTopPosition = document.body.getBoundingClientRect().top
        if (bodyTopPosition < -64) {
          showWhiteBackground.value = true
        } else {
          showWhiteBackground.value = false
        }
      })
    }
    const go = (data) => {
      router.replace(data)
    }
    const WorkTable = () => {
      router.push('/')
    }
    const aboutUs = () => {
      window.open('https://www.baidu.com/')
    }
    const login = () => {
      window.open(
        developerConfig.ssoPageUrl + '?target=' + window.location.href,
        '_self'
      )
    }
    const handleCommand = (c) => {
      switch (c) {
        case 'developmentGuide':
          router.push('/DevelopmentDocument')
          break
        case 'APIDocumentation':
          router.push('/DevelopmentDocument')
          break
      }
    }
    const handleSelect = () => {
      localStorage.setItem('activeTop', null)
      localStorage.setItem('dropdownId', 29)
      if (localStorage.getItem('activeName') === '开发示例') {
        localStorage.setItem('activeName', '开发指南')
      }
    }
    nextTick(() => {
      // if (asd.value == '/DevelopmentTool') {
      //   var arrowT = document.getElementsByClassName('el-sub-menu__title')
      //   arrowT[0].childNodes[3].style.color = '#ccc'
      // }
      if (asd.value == '/') {
        var arrowH = document.getElementsByClassName('header')
        arrowH[0].style.position = 'fixed'
      }
      if (asd.value != '/') {
        var arrowH = document.getElementsByClassName('header')
        arrowH[0].style.backgroundColor = '#303133'
        var arrowB = document.getElementsByClassName('bg-color')
        var arrN = [] //定义数组
        for (var i in arrowB) {
          arrN.push(arrowB[i])
        }
        arrN.forEach((element) => {
          element && element.style && (element.style.color = '#ccc')
        })
      }
      // watch(
      //   () => showWhiteBackground.value,
      //   (newValue, oldValue) => {
      //     if (showWhiteBackground.value === true) {
      //       var arrowT = document.getElementsByClassName('el-sub-menu__title')
      //       console.log(arrowT)
      //       arrowT[0].childNodes[3].style.color = '#ccc'
      //       arrowT[1].childNodes[3].style.color = '#ccc'
      //     }
      //   }
      // )
    })
    onMounted(() => {
      if (asd.value !== '/') {
        showWhiteBackground.value = true
      }
      cookies.value = inject('$cookies')
      if (cookies.value.get('token')) {
        userLogin.value = true
        store.commit('app/setToken', cookies.value.get('token'))
        getUserInfApi()
          .then((res) => {
            store.commit('app/setInfo', res.data.data.data)
          })
          .catch((err) => {
            console.log(err)
          })
      }
    })
    return {
      activeIndex,
      showWhiteBackground,
      asd,
      userLogin,
      WorkTable,
      login,
      handleCommand,
      handleSelect,
    }
  },
  mounted() {},
}
</script>

<style scoped>
.header {
  width: 100%;
  height: 64px;
  padding: 0 40px;
  box-sizing: border-box;
  /* position: fixed; */
  /* top: 0; */
  z-index: 1000;
  background-color: #fff;
  box-shadow: 0px 6px 16px 4px rgba(0, 0, 0, 0.04),
    0px 4px 10px rgba(0, 0, 0, 0.08);
  /* margin-top: 64px; */
}

.bg-transparent {
  background-color: transparent;
  box-shadow: 0 0px 0px transparent;
}

.el-menu--horizontal {
  border-bottom: 0;
}

.el-menu .el-menu-item.is-active {
  background: transparent;
  border-radius: 0;
}

.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom-color: #2ebad2;
  color: #2ebad2 !important;
}

.el-menu-ylcloud {
  background-color: transparent;
  display: flex;
  align-items: center;
}

.el-menu .el-menu-item:hover {
  color: #2ebad2;
  background-color: transparent !important;
}

.el-menu-ylcloud .el-menu-item {
  color: #ccc;
}

.el-menu-ylcloud .bg-color {
  color: rgba(0, 0, 0, 0.9);
}
.header-r {
  text-align: center;
  cursor: pointer;
}

.header-r-l {
  margin-right: 25px;
}

.header-r p {
  font-weight: 500;
  font-size: 12px;
  color: #2ebad2;
}
</style>
