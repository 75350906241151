<template>
  <div class="developer-layout">
    <el-container>
      <Header></Header>
      <el-main>
        <el-carousel height="640px">
          <!-- <el-carousel-item v-for="item in 4" :key="item">
            <h3 class="small justify-center" text="2xl">{{ item }}</h3>
          </el-carousel-item> -->
          <el-carousel-item v-for="(item, key) in titleList" :key="key">
            <div class="w1280">
              <h3
                class="wow animate__animated animate__fadeInUp"
                data-wow-duration="1s"
              >
                {{ item.title }}
              </h3>
              <h6
                class="wow animate__animated animate__fadeInUp"
                data-wow-duration="2.5s"
              >
                {{ item.title1 }}
              </h6>
              <p
                class="wow animate__animated animate__fadeInUp"
                data-wow-duration="3.5s"
              >
                {{ item.ms }}
              </p>
              <!-- <el-button
                class="wow animate__animated animate__fadeInUp"
                data-wow-duration="2s"
                @click="openLogin()"
                >立即认证</el-button -->
              >
            </div>
          </el-carousel-item>
        </el-carousel>
        <!-- <el-carousel
          :interval="5000"
          arrow="always"
          height="640px"
          indicator-position="none"
          loop="true"
        >
          <el-carousel-item v-for="(item, key) in titleList" :key="key">
            <div class="w1280">
              <h3
                class="wow animate__animated animate__fadeInUp"
                data-wow-duration="1s"
              >
                {{ item.title }}
              </h3>
              <h6
                class="wow animate__animated animate__fadeInUp"
                data-wow-duration="2.5s"
              >
                {{ item.title1 }}
              </h6>
              <p
                class="wow animate__animated animate__fadeInUp"
                data-wow-duration="3.5s"
              >
                {{ item.ms }}
              </p>
              <el-button
                class="wow animate__animated animate__fadeInUp"
                data-wow-duration="2s"
                @click="openLogin()"
                >立即认证</el-button
              >
            </div>
          </el-carousel-item>
        </el-carousel> -->
        <!-- 精品开发案例示范 -->
        <div class="w1280 content1">
          <h4
            class="wow animate__animated animate__fadeInUp"
            data-wow-duration="2s"
          >
            精品开发案例示范
          </h4>
          <p
            class="wow animate__animated animate__fadeInUp title_ms"
            data-wow-duration="3s"
          >
            优立致力于构建开发者友好的开放、开源开发平台。解决三维模型的技术瓶颈：多源格式、数据体量大
          </p>
          <el-row justify="space-between">
            <el-col
              :span="6"
              v-for="(item, key) in caseList1"
              :key="key"
              class="wow animate__animated animate__fadeInUp"
              :data-wow-duration="item.time"
            >
              <div class="case-box-t">
                <img :src="item.img" alt="" />
                <svg
                  v-if="item.showIcon"
                  class="video-icon"
                  @click="openVideoChange(item)"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_499_2510)">
                    <path
                      d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z"
                      fill="white"
                      fill-opacity="0.2"
                    />
                    <path
                      d="M23.7697 16.4399L12.8039 23.9115C12.726 23.9644 12.6355 23.9948 12.542 23.9994C12.4486 24.004 12.3556 23.9825 12.2731 23.9374C12.1906 23.8923 12.1217 23.8251 12.0736 23.7431C12.0255 23.661 12 23.5672 12 23.4716V8.52843C12 8.43279 12.0255 8.33896 12.0736 8.25693C12.1217 8.1749 12.1906 8.10774 12.2731 8.0626C12.3556 8.01746 12.4486 7.99603 12.542 8.0006C12.6355 8.00517 12.726 8.03557 12.8039 8.08855L23.7697 15.5601C23.8405 15.6084 23.8986 15.6738 23.9388 15.7506C23.979 15.8273 24 15.913 24 16C24 16.087 23.979 16.1727 23.9388 16.2494C23.8986 16.3262 23.8405 16.3916 23.7697 16.4399Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_499_2510">
                      <rect width="32" height="32" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>

              <div class="case-box-b">
                <a :href="item.hrefName" target="_blank"
                  ><h5>{{ item.title }}</h5></a
                >
                <p>{{ item.ms }}</p>
              </div>
            </el-col>
          </el-row>
          <el-row justify="space-between">
            <el-col
              :span="6"
              v-for="(item, key) in caseList2"
              :key="key"
              class="content1-c wow animate__animated animate__fadeInUp"
              :data-wow-duration="item.time"
            >
              <div class="case-box-t">
                <img :src="item.img" alt="" />
                <svg
                  v-if="item.showIcon"
                  class="video-icon"
                  @click="openVideoChange(item)"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_499_2510)">
                    <path
                      d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z"
                      fill="white"
                      fill-opacity="0.2"
                    />
                    <path
                      d="M23.7697 16.4399L12.8039 23.9115C12.726 23.9644 12.6355 23.9948 12.542 23.9994C12.4486 24.004 12.3556 23.9825 12.2731 23.9374C12.1906 23.8923 12.1217 23.8251 12.0736 23.7431C12.0255 23.661 12 23.5672 12 23.4716V8.52843C12 8.43279 12.0255 8.33896 12.0736 8.25693C12.1217 8.1749 12.1906 8.10774 12.2731 8.0626C12.3556 8.01746 12.4486 7.99603 12.542 8.0006C12.6355 8.00517 12.726 8.03557 12.8039 8.08855L23.7697 15.5601C23.8405 15.6084 23.8986 15.6738 23.9388 15.7506C23.979 15.8273 24 15.913 24 16C24 16.087 23.979 16.1727 23.9388 16.2494C23.8986 16.3262 23.8405 16.3916 23.7697 16.4399Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_499_2510">
                      <rect width="32" height="32" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div class="case-box-b">
                <a :href="item.hrefName" target="_blank"
                  ><h5>{{ item.title }}</h5></a
                >
                <p>{{ item.ms }}</p>
              </div>
            </el-col>
            <!-- <el-col
              :span="6"
              class="learn-more wow animate__animated animate__fadeInUp"
              data-wow-duration="2.4s"
            >
              <el-link :underline="false" target="_blank" @click="handleMore()">
                <div style="text-align: center">
                  <svg
                    width="48"
                    height="48"
                    viewBox="0 0 48 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      r="23.5"
                      transform="matrix(-1 0 0 1 24 24)"
                      stroke="#909399"
                    />
                    <g clip-path="url(#clip0_7_80)">
                      <path
                        d="M25.172 24L20.222 28.95L21.636 30.364L28 24L21.636 17.636L20.222 19.05L25.172 24Z"
                        fill="#909399"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_7_80">
                        <rect
                          width="24"
                          height="24"
                          fill="white"
                          transform="matrix(-1 0 0 1 36 12)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                  <p>了解更多</p>
                </div>
              </el-link>
            </el-col> -->
          </el-row>
          <div
            class="wow animate__animated animate__fadeInUp title_ms"
            data-wow-duration="6s"
            @click="handleMore()"
            style="
              display: flex;
              align-items: center;
              font-size: 14px;
              color: var(--unnamed, #606266);
              justify-content: flex-end;
              margin-top: 20px;
              cursor: pointer;
            "
          >
            了解更多<svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M5.32801 3.00001L10.203 8.00001L5.32801 13C5.23435 13.0937 5.18751 13.203 5.18751 13.328C5.18751 13.453 5.23435 13.5623 5.32801 13.656C5.42168 13.7497 5.53101 13.7965 5.65601 13.7965C5.78101 13.7965 5.88518 13.7548 5.96851 13.6715L11.156 8.34351C11.2497 8.24984 11.2965 8.13518 11.2965 7.99951C11.2965 7.86384 11.2497 7.74918 11.156 7.65551L5.98401 2.32751C5.89035 2.24418 5.77835 2.20251 5.64801 2.20251C5.51768 2.20251 5.40835 2.24934 5.32001 2.34301C5.23168 2.43668 5.18735 2.54601 5.18701 2.67101C5.18668 2.79601 5.23351 2.90534 5.32751 2.99901L5.32801 3.00001Z"
                fill="#606266"
              />
            </svg>
          </div>
        </div>
        <div class="content2-bg">
          <div class="w1280 content2">
            <h4
              class="wow animate__animated animate__fadeInUp"
              data-wow-duration="2s"
            >
              产品开发教程
            </h4>
            <p
              class="wow animate__animated animate__fadeInUp title_ms"
              data-wow-duration="3s"
            >
              SDK service
            </p>
            <el-row justify="space-between">
              <el-col
                :span="12"
                v-for="(item, key) in tutorialList"
                :key="key"
                class="wow animate__animated animate__fadeInUp"
                :data-wow-duration="item.time"
              >
                <div class="tutorial-list">
                  <div class="tutorial-title u-f-jsb">
                    <h6>{{ item.title }}</h6>
                    <span v-if="item.labelName == '软件产品'" class="green">{{
                      item.labelName
                    }}</span>
                    <span v-else class="blue">{{ item.labelName }}</span>
                  </div>
                  <div class="tutorial-ms">
                    {{ item.ms }}
                  </div>
                  <el-link
                    :underline="false"
                    target="_blank"
                    @click="
                      tutorialMore(
                        item.path,
                        item.activeTopNum,
                        item.dropdownIdNum
                      )
                    "
                    >了解更多></el-link
                  >
                </div>
              </el-col>
            </el-row>
          </div>
        </div>

        <div class="content3-bg">
          <div class="w1280 content3">
            <h4
              class="wow animate__animated animate__fadeInUp"
              data-wow-duration="2s"
            >
              优立开发平台优势
            </h4>
            <p
              class="wow animate__animated animate__fadeInUp title_ms"
              data-wow-duration="3s"
            >
              优立发挥开发产品优势，助力开发者挖掘三维可视化价值，实现数字孪生项目全流程搭建
            </p>
            <div class="dominant-content">
              <div class="dominant-content-l">
                <div
                  class="dominant-content-example wow animate__animated animate__fadeInUp"
                  :data-wow-duration="item.time"
                  v-for="(item, key) in exampleList1"
                  :key="key"
                >
                  <h6>{{ item.title }}</h6>
                  <p>{{ item.ms }}</p>
                </div>
              </div>
              <img src="../assets/imgs/yspt.png" alt="" />
              <div class="dominant-content-r">
                <div
                  class="dominant-content-example wow animate__animated animate__fadeInUp"
                  :data-wow-duration="item.time"
                  v-for="(item, key) in exampleList2"
                  :key="key"
                >
                  <h6>{{ item.title }}</h6>
                  <p>{{ item.ms }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="w1280 content4">
          <h4
            class="wow animate__animated animate__fadeInUp"
            data-wow-duration="2s"
          >
            合作伙伴
          </h4>
          <p
            class="wow animate__animated animate__fadeInUp title_ms"
            data-wow-duration="3s"
          >
            越来越多的合作伙伴与优立一起，构建数字孪生开放生态平台
          </p>
          <div
            style="display: flex; justify-content: center"
            class="wow animate__animated animate__fadeInUp"
            data-wow-duration="2.5s"
          >
            <img src="../assets/imgs/hzhb.png" alt="" />
          </div>
        </div>
        <!-- 案例视频 -->
        <el-dialog
          v-model="videoList.dialogVisible"
          width="70%"
          style="height: 580px"
          :before-close="handleClose"
          class="case-video"
        >
          <video
            ref="player"
            style="width: 100%; height: 100%"
            controls
            autoplay
            @opened="openVideoChange"
            :src="videoList.videoHref"
          ></video>
          <div class="button_box" v-if="videoList.id == 1">
            <el-button
              type="primary"
              v-if="showBtn && hrefNum == 1"
              @click="nextChange()"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16 12.6667L5.77735 19.4818C5.54759 19.6349 5.23715 19.5729 5.08397 19.3431C5.02922 19.261 5 19.1645 5 19.0657V4.93426C5 4.65812 5.22386 4.43426 5.5 4.43426C5.59871 4.43426 5.69522 4.46348 5.77735 4.51823L16 11.3333V5C16 4.44772 16.4477 4 17 4C17.5523 4 18 4.44772 18 5V19C18 19.5523 17.5523 20 17 20C16.4477 20 16 19.5523 16 19V12.6667Z"
                  fill="white"
                />
              </svg>
              下一个
            </el-button>
            <el-button
              type="primary"
              v-if="showBtn && hrefNum == 2"
              @click="replayChange()"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.46257 4.43262C7.21556 2.91688 9.5007 2 12 2C17.5228 2 22 6.47715 22 12C22 14.1361 21.3302 16.1158 20.1892 17.7406L17 12H20C20 7.58172 16.4183 4 12 4C9.84982 4 7.89777 4.84827 6.46023 6.22842L5.46257 4.43262ZM18.5374 19.5674C16.7844 21.0831 14.4993 22 12 22C6.47715 22 2 17.5228 2 12C2 9.86386 2.66979 7.88416 3.8108 6.25944L7 12H4C4 16.4183 7.58172 20 12 20C14.1502 20 16.1022 19.1517 17.5398 17.7716L18.5374 19.5674Z"
                  fill="white"
                />
              </svg>
              重播
            </el-button>
          </div>
        </el-dialog>
      </el-main>
      <el-footer>
        <Footer></Footer>
      </el-footer>
    </el-container>
  </div>
</template>
<script>
import { onBeforeUnmount, onMounted, ref, nextTick, inject } from 'vue'
import Header from '@/components/layouts/Header'
import Footer from '@/components/layouts/Footer'
import { useRouter } from 'vue-router'

export default {
  name: 'MyApp',
  components: {
    Header,
    Footer,
  },

  data() {
    return {
      titleList: [
        {
          title: '优立开发者平台',
          title1: '构建开源、开放、共享的三维数字孪生软件开发者生态',
          ms: 'udStream SDK/ udSDK/ udUE SDK/udUnity SDK',
          buttonName: '立即认证',
        },
        {
          title: '优立开发者平台',
          title1:
            '构建多元游戏引擎、适配优立硬件全息设备环境、三维数字孪生硬件开发者生态',
          ms: 'Unity ToolKit、UE ToolKit、osgToolKit',
          buttonName: '立即认证',
        },
      ],
      caseList1: [
        {
          id: 1,
          img: 'https://developer.eulee.cn/files/img/udstreamcoverpage1.gif',
          title: 'udStream SDK 开发示例',
          time: '1s',
          ms: 'udStreamSDK.js是网页端三维引擎 javascript库程序，助力您构建无限细节网页端三维数字孪生底座。',
          hrefName: 'udapi/udStreamSDK/example/',
          dialogVisible: false,
          showIcon: true,
          videoHref:
            'https://developer.eulee.cn/files/video/udstreamsdkvideo1.mp4',
        },
        {
          id: 2,
          img: './case/case2.png',
          title: 'udSDK 开发示例',
          time: '1.2s',
          ms: 'udSDK是客户端三维引擎，纯原生SDK开发，助力您构建无限细节客户端底层三维数字孪生底座。',
          dialogVisible: false,
          showIcon: false,
          videoHref: '',
        },
        {
          id: 3,
          img: 'https://developer.eulee.cn/files/img/udunitycoverpage.gif',
          title: 'udUnity SDK 开发示例',
          time: '1.4s',
          ms: 'udUnity SDK是客户端Unity游戏引擎框架三维引擎，助力您构建无限细节客户端游戏三维数字孪生底座。',
          dialogVisible: false,
          showIcon: true,
          videoHref: 'https://developer.eulee.cn/files/video/udUnityvideo.mp4',
        },
        {
          id: 4,
          img: 'https://developer.eulee.cn/files/img/udUEcoverpage.gif',
          title: 'udUE SDK 开发示例',
          time: '1.6s',
          ms: 'udUE SDK是客户端Unreal 虚幻游戏引擎框架三维引擎，助力您构建无限细节客户端游戏三维数字孪生底座。',
          dialogVisible: false,
          showIcon: true,
          videoHref: 'https://developer.eulee.cn/files/video/udUEvideo.mp4',
        },
      ],
      caseList2: [
        {
          id: 5,
          img: 'https://developer.eulee.cn/files/img/UnityToolkitcoverpage.gif',
          title: 'Unity ToolKit 开发示例',
          time: '1.8s',
          ms: 'Unity Toolkit是专为优立全息设备所设的三维游戏引擎开发工具包，可接入优立全息设备沙盘、全息墙。',
          dialogVisible: false,
          showIcon: true,
          videoHref:
            'https://developer.eulee.cn/files/video/UnityToolkitvideo.mp4',
        },
        {
          id: 6,
          img: './case/case6.png',
          title: 'UE ToolKit 开发示例',
          time: '2s',
          ms: 'UE Toolkit是专为优立全息设备所设的三维游戏引擎开发工具包，可接入优立全息设备沙盘、全息墙。',
          dialogVisible: false,
          showIcon: false,
        },
        {
          id: 7,
          img: './case/case7.png',
          title: 'OSG Toolkit 开发示例',
          time: '2.2s',
          ms: 'OSG Toolkit是专为优立全息设备所设的三维游戏引擎开发工具包，可接入优立全息设备沙盘、全息墙。',
          dialogVisible: false,
          showIcon: false,
        },
        {
          id: 8,
          img: './case/case8.png',
          title: 'udCesium 开发示例',
          time: '2.4s',
          ms: 'udCesium.js 是将uds模型加载在cesium中的三维 javascript库，助力您构建无限细节网页端地球三维数字孪生底座。',
          dialogVisible: false,
          showIcon: false,
        },
      ],
      tutorialList: [
        {
          title: 'udStream SDK.js',
          labelName: '软件产品',
          time: '1s',
          ms: 'udStreamSDK.js 是网页端三维引擎 javascript库程序， 开放优立无限细节（简称UD）点云渲染技术接口，让开发团队可通过 API 接入现有系统，对您自己的系统进行产品底层的升级开发',
          hrefName: 'udapi/udStreamSDK/api/',
          path: '/DevelopmentTool/contentDetailTool/95',
          activeTopNum: '35',
          dropdownIdNum: '29',
        },
        {
          title: 'udSDK',
          labelName: '软件产品',
          time: '1.2s',
          ms: 'udSDK是三维引擎 javascript、python、C++、C#库，纯原生SDK开发包，开放优立无限细节渲染技术底层接口，为具备深层次开发能力的开发者提供最底层核心的udSDK，客户可以基于此从最底层开发面向android、macos、windows的平台软件。',
          path: '/DevelopmentTool/contentDetailTool/98',
          activeTopNum: '38',
          dropdownIdNum: '30',
        },
        {
          title: 'udUE SDK',
          labelName: '软件产品',
          time: '1.4s',
          ms: 'udUE- SDK是三维引擎C#、C++库，提供基于UE虚幻游戏引擎框架，开放优立无限细节渲染技术底层接口，为具备深层次开发能力的开发者提供最底层核心，客户可以基于此从最底层开发面向游戏虚幻引擎的三维无限细节场景平台。',
          path: '/DevelopmentTool/contentDetailTool/103',
          activeTopNum: '42',
          dropdownIdNum: '32',
        },
        {
          title: 'udUnity-SDK',
          labelName: '软件产品',
          time: '1.6s',
          ms: 'udUnity- SDK是三维引擎C#库，提供基于Unity游戏引擎框架，开放优立无限细节渲染技术底层接口，为具备深层次开发能力的开发者提供最底层核心，客户可以基于此从最底层开发面向Unity游戏引擎的三维无限细节场景平台。',
          path: '/DevelopmentTool/contentDetailTool/100',
          activeTopNum: '40',
          dropdownIdNum: '31',
        },
        {
          title: 'Unity ToolKit',
          labelName: '全息设备',
          time: '1.8s',
          ms: 'Unity Toolkit是专为优立全息设备所设的三维游戏引擎开发工具包， 提供基于Unity游戏引擎框架，开放优立无限细节（简称UD）点云渲染技术接口，让开发团队可通过 API 接入优立全息设备系统，包括全息沙盘、全息墙。',
          path: '/DevelopmentTool/contentDetailTool/105',
          activeTopNum: '44',
          dropdownIdNum: '33',
        },
        {
          title: 'UE ToolKit',
          labelName: '全息设备',
          time: '2s',
          ms: 'UE Toolkit是专为优立全息设备所设的三维游戏引擎开发工具包， 提供基于unreal 虚幻游戏引擎框架，开放优立无限细节（简称UD）点云渲染技术接口，让开发团队可通过 API 接入优立全息设备系统，包括全息沙盘、全息墙。',
          path: '/DevelopmentTool/contentDetailTool/107',
          activeTopNum: '46',
          dropdownIdNum: '34',
        },
        // {
        //   title: 'OSG ToolKit',
        //   labelName: '全息设备',
        //   time: '2.2s',
        //   ms: 'OSG Toolkit是专为优立全息设备所设的二次开发工具包， 提供基于osg引擎框架，开放优立无限细节（简称UD）点云渲染技术接口，让开发团队可通过 API 接入优立全息设备系统，包括全息沙盘、全息墙。',
        //   path: '/osgToolkitGuide',
        // },
      ],
      exampleList1: [
        {
          title: '国产自主可控支援信创环境',
          ms: '通过信创环境认证，支持银河麒麟、统信UOS、支持Windows、Ubuntu 版本 X86 ARM 平台部署，完全国产、自主可控，提升安全性',
          time: '1s',
        },
        {
          title: '开源开放共享定制享零门槛',
          ms: '海量三维数据可视化、海量三维场景搭建的开发示范案例，降低业务定制门槛，开发者友好更快上手版本 X86 ARM 平台部署，完全国产、自主可控，提升安全性',
          time: '1.2s',
        },
        {
          title: '灵活拓展即改即用',
          ms: '优立开发者平台，无需烘焙三维场景、开发者可修改数字孪生开发示例，形成各类智慧城市运用，实现现场配置数字孪生城市业务即改即用',
          time: '1.4s',
        },
      ],
      exampleList2: [
        {
          title: '立即注册登录获取开发示范',
          ms: '快速认证、快速获取，平台开源、跨平台引擎、SDK开发包、开发示例、在线精品开发案例、模型优化服务、共享模型库、资源中心。',
          time: '1s',
        },
        {
          title: '专利三维算法海量模型应用',
          ms: '专利海量三维数据算法，解决多源三维模型在项目中的可视化呈现包括倾斜摄影、激光扫描、人工建模，不依赖硬件堆叠，实现TB级数据，0.8s秒级加载',
          time: '1.2s',
        },
        {
          title: '多架构展现多数据融合',
          ms: '优立SDK开发平台，涵盖B/S架构、C/S架构开发资源示范，与合作伙伴产品、各类企业软件集成，有效的无缝融合，摆脱数字孪生场景的软件使用割裂局面',
          time: '1.4s',
        },
      ],
      videoList: {
        dialogVisible: false,
        videoHref: '',
        id: 0,
      },
      hrefNum: 0,
      showBtn: false,
      cookies: '',
    }
  },

  setup() {},
  methods: {
    handleMore() {
      console.log(this.$cookies)
      if (this.$cookies.get('token')) {
       // this.$router.push('/DevelopmentTool/CaseLink?caseLink=https://demo.eulee.cn/example/')
        var link = 'https://demo.eulee.cn/example/'
        this.$router.push({
          path: '/DevelopmentTool/CaseLink',
          query: { caseLink: link },
        })
        localStorage.setItem('activeTop', 73)
        localStorage.setItem('dropdownId', 29)
        // localStorage.setItem('activeTop', 55)
        // localStorage.setItem('dropdownId', 26)
        //window.open('https://developer.eulee.cn/#/DevelopmentTool/CaseLink?caseLink=https://demo.eulee.cn/example/')
      } else {
        window.open(
          developerConfig.ssoPageUrl + '?target=' + window.location.href,
          '_self'
        )
      }
    },
    tutorialMore(path, activeTopNum, dropdownIdNum) {
      if (this.$cookies.get('token')) {
        this.$router.push(path)
        localStorage.setItem('activeTop', activeTopNum)
        localStorage.setItem('dropdownId', dropdownIdNum)
      } else {
        window.open(
          developerConfig.ssoPageUrl + '?target=' + window.location.href,
          '_self'
        )
      }
    },
    handlePlayEvt() {
      console.log('playing--------------------')
    },
    tutorialChange(e) {
      const userId = e
      router.replace({ name: e, params: { userId } })
      console.log(router.push({ name: e, params: { userId } }))
    },
    openVideoChange(e) {
      this.videoList = e
      this.videoList.dialogVisible = true
      nextTick(() => {
        this.$refs.player.play()
        // this.$refs.player.onplaying = (e) => {
        //   console.log(this.$refs.player.src, 'onplaying--------------------')
        // }
        // this.$refs.player.onpause = (e) => {
        //   console.log('pause--------------------')
        // }
        this.$refs.player.onended = (e) => {
          // console.log('end--------------------', this.$refs.player.src)
          this.hrefNum += 1
          this.showBtn = true
          // this.$refs.player.src =
          //   'https://developer.eulee.cn/files/video/udstreamsdkvideo2.mp4'
        }
      })
    },
    nextChange() {
      this.$refs.player.src =
        'https://developer.eulee.cn/files/video/udstreamsdkvideo2.mp4'
      this.showBtn = false
      this.$refs.player.onended = (e) => {
        // console.log('end--------------------', this.$refs.player.src)
        this.hrefNum += 1
        this.showBtn = true
      }
      console.log(
        'this.$refs.player.src--------------------',
        this.$refs.player.src
      )
    },
    replayChange() {
      this.$refs.player.src =
        'https://developer.eulee.cn/files/video/udstreamsdkvideo1.mp4'
      this.hrefNum = 0
      this.showBtn = false
      console.log(
        'this.$refs.player.src--------------------',
        this.$refs.player.src
      )
    },
    handleClose() {
      this.videoList.dialogVisible = false
      this.hrefNum = 0
      this.showBtn = false
      nextTick(() => {
        this.$refs.player.pause()
      })
    },
  },
}
</script>

<style>
.el-carousel__item {
  padding-top: 10%;
  box-sizing: border-box;
}
.el-carousel__item h3 {
  width: 900px;
  font-weight: 600;
  font-size: 64px;
  color: #ffffff;
}
.el-carousel__item h6 {
  width: 900px;
  font-weight: 600;
  font-size: 32px;
  color: #ffffff;
}
.el-carousel__item p {
  font-weight: 400;
  font-size: 20px;
  color: #ffffff;
  margin-top: 24px;
}
.el-carousel__item .el-button {
  margin-top: 76px;
  background: linear-gradient(
    281.98deg,
    #2ebad2 -1.74%,
    #2ed9c3 63.39%,
    #f5d93f 121.41%
  );
  box-shadow: 0px 12px 32px 4px rgba(46, 186, 210, 0.08),
    0px 8px 20px rgba(46, 186, 210, 0.16);
  border-radius: 8px;
  color: #ffffff;
  font-size: 20px;
  height: 47px;
  padding: 8px 80px;
  bottom: 32px;
  left: 32px;
}
.el-carousel__item:nth-child(2n + 1) {
  background: url(@/assets/imgs/banner1.png) 0 0 no-repeat;
  background-size: cover;
}

.el-carousel__item:nth-child(2n) {
  background: url(@/assets/imgs/banner2.png) 0 0 no-repeat;
  background-size: cover;
}
.el-carousel {
  position: relative;
}
.developer-layout .el-main {
  padding: 0 0 0px 0;
  overflow: hidden;
}
.el-carousel__item h3 {
  width: 900px;
  font-weight: 600;
  font-size: 64px;
  color: #ffffff;
  margin-bottom: 20px;
}
.el-carousel__item p {
  font-weight: 400;
  font-size: 20px;
  color: #ffffff;
  margin-top: 24px;
}
.el-carousel {
  position: relative;
}
.developer-layout .el-footer {
  margin-top: 50px;
  height: 276px;
  background: #f6f9fb;
  border-bottom: 0.5px solid #dcdfe6;
  padding: 0;
}
.content1 h4 {
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  color: rgba(0, 0, 0, 0.9);
  position: relative;
  margin-top: 40px;
}
.content1 h4::after {
  content: '';
  display: inline-block;
  position: absolute;
  width: 108px;
  height: 3px;
  background: #2ebad2;
  left: 50%;
  margin-left: -52px;
  bottom: -60px;
}
.content1 .title_ms {
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  color: #909399;
  margin-top: 20px;
  margin-bottom: 20px;
}
.content1 .el-col-6 {
  max-width: 23.438%;
  margin-top: 20px;
  border: 1px solid #dcdfe6;
  overflow: hidden;
}
.content1 .learn-more {
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content1 .learn-more .el-link {
  width: 100%;
  height: 100%;
}
.content1 .learn-more p {
  font-size: 16px;
  margin-top: 8px;
}
.content1 .learn-more:hover {
  color: #00abc8;
}
.content1 .el-col-6 .case-box-t {
  overflow: hidden;
  width: 100%;
  height: 168px;
  position: relative;
}
.video-icon {
  position: absolute;
  left: 16px;
  bottom: 16px;
  cursor: pointer;
}
.case-box-t img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.content1 .el-col-6 .case-box-b {
  padding: 18px;
}
.content1 .el-col-6 .case-box-b h5 {
  font-size: 16px;
  color: #000000;
}
.content1 .el-col-6 .case-box-b p {
  font-size: 14px;
  color: #000000;
  line-height: 20px;
  margin-top: 15px;
}
.content1 .case-box-t img {
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
}
.content1 .case-box-t:hover > img {
  -webkit-transform: scale(1.3);
  -moz-transform: scale(1.3);
  -ms-transform: scale(1.3);
  transform: scale(1.3);
}
.content2-bg {
  margin-top: 90px;
  padding: 58px 0;
  background: url(../assets/imgs/content2-bg.png) 0 0 no-repeat;
  background-size: 100%;
}
.content2 h4 {
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  color: rgba(0, 0, 0, 0.9);
  position: relative;
  margin-top: 40px;
}
.content2 h4::after {
  content: '';
  display: inline-block;
  position: absolute;
  width: 108px;
  height: 3px;
  background: #2ebad2;
  left: 50%;
  margin-left: -52px;
  bottom: -60px;
}
.content2 .title_ms {
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  color: #909399;
  margin-top: 20px;
  margin-bottom: 30px;
}
.content2 .el-col-12 {
  max-width: 49.063%;
  margin-top: 24px;
  background-color: #fff;
}
.content2 .el-col-12:hover {
  background: #fafcff;
}
.content2 .tutorial-list {
  border-top: 2px solid #007b97;
  box-shadow: 0px 12px 32px 4px rgba(0, 0, 0, 0.04),
    0px 8px 20px rgba(0, 0, 0, 0.08);
}
.tutorial-list .tutorial-title {
  height: 64px;
  padding: 0 32px;
  border-bottom: 1px solid #dcdfe6;
}
.tutorial-list .tutorial-title h6 {
  font-size: 18px;
  color: #303133;
}
.tutorial-list .tutorial-title span {
  width: 88px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  font-size: 14px;
  border-radius: 100px;
}
.green {
  background: #f0f9eb;
  color: #67c23a;
}
.blue {
  background: #e6ffff;
  color: #00abc8;
}
.tutorial-list .tutorial-ms {
  padding: 10px 32px 16px 32px;
  height: 78px;
  font-size: 14px;
  color: #606266;
}
.tutorial-list .el-link {
  padding-left: 32px;
  padding-bottom: 32px;
  color: #303133;
  font-size: 18px;
}
.tutorial-list .el-link:hover {
  color: #00abc8;
}
.content3-bg {
  padding: 0px 0 58px 0;
  background: url(../assets/imgs/content3-bg.png) 0 0 no-repeat;
  background-size: 100%;
  border-bottom: 1px solid #e4e7ed;
}
.content3 h4 {
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  color: rgba(0, 0, 0, 0.9);
  position: relative;
  margin-top: 40px;
}
.content3 h4::after {
  content: '';
  display: inline-block;
  position: absolute;
  width: 108px;
  height: 3px;
  background: #2ebad2;
  left: 50%;
  margin-left: -52px;
  bottom: -60px;
}
.content3 .title_ms {
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  color: #909399;
  margin-top: 20px;
  margin-bottom: 30px;
}
.content3 .dominant-content {
  margin-top: 60px;
  display: flex;
  justify-content: center;
}
.content3 .dominant-content-l,
.content3 .dominant-content-r {
  width: 260px;
}
.dominant-content-example {
  box-sizing: border-box;
}
.dominant-content-example h6 {
  font-size: 16px;
  color: #303133;
  margin-bottom: 10px;
}
.dominant-content-l h6 {
  text-align: right;
}
.dominant-content-l p {
  text-align: right;
}
.dominant-content-example p {
  color: #606266;
  font-size: 14px;
}
.dominant-content-l {
  margin-right: 16px;
}
.dominant-content-r {
  margin-left: 16px;
}
.dominant-content-l .dominant-content-example:nth-child(1) {
  padding-top: -5px;
}
.dominant-content-l .dominant-content-example:nth-child(2) {
  padding-top: 45px;
}
.dominant-content-l .dominant-content-example:nth-child(3) {
  padding-top: 60px;
}
.dominant-content-r .dominant-content-example:nth-child(1) {
  padding-top: -5px;
}
.dominant-content-r .dominant-content-example:nth-child(2) {
  padding-top: 45px;
}
.dominant-content-r .dominant-content-example:nth-child(3) {
  padding-top: 60px;
}
.content4 {
  margin-top: 35px;
  margin-bottom: 80px;
}
.content4 img {
  margin-top: 35px;
}
.content4 h4 {
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  color: rgba(0, 0, 0, 0.9);
  position: relative;
  margin-top: 40px;
}
.content4 h4::after {
  content: '';
  display: inline-block;
  position: absolute;
  width: 108px;
  height: 3px;
  background: #2ebad2;
  left: 50%;
  margin-left: -52px;
  bottom: -60px;
}
.content4 .title_ms {
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  color: #909399;
  margin-top: 20px;
  margin-bottom: 30px;
}
</style>
