import {
    queryFaqList
} from '@/utils/questionsAnswers'

export default {
    namespaced: true,
    state: () => ({
        faqList: [],
        page: 1,
        pageSize: 20,
        total_record: 20,
        tabActiveName: '',
        has_nextpage: '',
        showLoadMore: false,
        parameter:null,
    }),
    mutations: {
        changeFaqList(state, data) {
            state.faqList = data
        },
        changeTotalrecord(state, data) {
            state.total_record = data
        },
        SET_QA_LIST(state, qaList) {
            // state.faqList.push(...qaList);
            state.faqList = qaList
        },
        SET_SHOW_LOAD_MORE(state, showLoadMore) {
            state.showLoadMore = showLoadMore;
        },
        INCREMENT_PAGE(state) {
            state.page++;
        },
        INCREMENT_PAGE_SIZE(state) {
            state.pageSize += 20;
        },
        setPage(state, page) {
            state.page = Number(page);
        },
        setParameter(state,payload) {
          state.parameter = payload
        }
    },
    actions: {
        getFaqList({
            commit,
            state
        }, params) {
            return new Promise((resolve, reject) => {
                let formdata = new FormData();
                if (params.hasOwnProperty('page')) {
                    formdata.append("page", params.page)
                } else {
                    // formdata.append("page", 1)
                    formdata.append("page", state.page)
                }

                formdata.append("pagesize", state.pageSize)
                if (params.hasOwnProperty('tags')) {
                    formdata.append("tag", params.tags);
                }
                if (params.hasOwnProperty('keyword')) {
                    formdata.append("keywords", params.keyword);
                }
                // console.log(params)
                queryFaqList(formdata)
                    .then((res) => {
                        // commit('changeFaqList', res.data.data.list)
                        // console.log(JSON.stringify(res.data.data.list,null,2))
                        // 更新问答列表数据
                        commit('SET_QA_LIST', res.data.data.list);
                        // commit('addQuestions', res.data.data.list)
                        // 判断是否还有更多数据
                        commit('SET_SHOW_LOAD_MORE', res.data.data.has_nextpage !== 'no');
                        // 增加页码
                        //commit('INCREMENT_PAGE');
                        commit('changeTotalrecord', res.data.data.total_record);
                        commit('setPage', res.data.data.current_page)
                        resolve()
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        }
    }
}