<template>
  <el-dialog
    class="ask-q-dis"
    :modelValue="dialogVisible"
    :close-on-click-modal="false"
    @close="resetForm(formRef)"
    :title="dialogTitle"
    width="65%"
    draggable
  >
    <div class="dialog_con">
      <el-form
        ref="ruleFormRef"
        :model="ruleForm"
        :rules="rules"
        class="demo-ruleForm"
        :size="formSize"
        status-icon
        :label-position="labelPosition"
      >
        <el-form-item prop="name">
          <el-input
            size="large"
            placeholder="问题标题：一句话描述问题，请以句号结尾。"
            v-model="ruleForm.name"
            maxlength="50"
          />
        </el-form-item>
        <el-form-item prop="type">
          <el-checkbox-group v-model="ruleForm.type">
            <el-checkbox-button
              v-for="(tag, index) in tagsList"
              :key="index"
              :label="tag.id"
            >
              {{ tag.name }}
            </el-checkbox-button>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item prop="desc" label="问题描述（可选）">
          <el-input v-model="ruleForm.desc" type="textarea" />
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <div class="u-f-lie">
        <span class="dialog-footer">
          <el-button
            :disabled="isDisable"
            type="primary"
            @click="submitForm(ruleFormRef)"
            >提交问题</el-button
          >
        </span>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import {
  ref,
  reactive,
  toRefs,
  getCurrentInstance,
  onMounted,
  watch,
  nextTick,
} from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { ElMessage } from 'element-plus'
import {
  communityCommitQuestion,
  queryFaqDetails,
  editQuestion,
} from '@/utils/questionsAnswers'
export default {
  name: '',
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    // queryDetails: {
    //   type: String,
    //   required: true,
    // },
  },
  emits: ['close'],
  setup(props, { emit }) {
    const isDisable = ref(false)
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const data = reactive({
      dialogTitle: '提问题',
    })
    const questionId = ref(null)
    const labelPosition = ref('top')
    const formSize = ref('default')
    const ruleFormRef = ref()
    const ruleForm = ref({
      name: '',
      type: [1],
      desc: '',
    })
    const tagsList = [
      { id: 1, name: '数据转换' },
      { id: 2, name: '软件使用' },
      { id: 3, name: '开发编码' },
    ]
    const rules = reactive({
      name: [
        {
          required: true,
          message: '请写下你的问题',
          trigger: 'blur',
        },
        // { min: 3, max: 50, message: '问题已超出 50 个字', trigger: 'blur' },
      ],
      type: [
        {
          type: 'array',
          required: true,
          message: '请给问题选择一个标签',
          trigger: 'change',
        },
      ],
    })

    const submitForm = (formEl) => {
      if (!formEl) return
      formEl.validate(async (valid) => {
        if (valid) {
          let formdata = new FormData()
          formdata.append('question_title', ruleForm.value.name)
          formdata.append('question_content', ruleForm.value.desc)
          formdata.append('tags', ruleForm.value.type)
          if (param.dialogTitle.value == '提问题') {
              isDisable.value = true
            setTimeout(() => {
              isDisable.value = false
            }, 1000)
            const data = await communityCommitQuestion(formdata)
            if (data.data.code == 1) {
              emit('close', false)
              store.dispatch('faq/getFaqList', false)
              ElMessage({
                type: 'success',
                message: data.data.msg,
              })
            }
          }
          if (param.dialogTitle.value == '编辑问题') {
            isDisable.value = true
            setTimeout(() => {
              isDisable.value = false
            }, 1000)
            formdata.append('question_id', questionId.value)
            const data = await editQuestion(formdata)
            if (data.data.code == 1) {
              emit('close', false)
              router.push('/DeveloperCommunity/MainDeveloperCommunity')
              ElMessage({
                type: 'success',
                message: data.data.msg,
              })
            }
          }

          ruleForm.value = {
            name: '',
            type: [1],
            desc: '',
          }
        } else {
          console.log('error submit!', fields)
          return false
        }
      })
    }

    const resetForm = (formEl) => {
      emit('close', false)
      ruleForm.value = {
        name: '',
        type: [1],
        desc: '',
      }
      if (!formEl) return
      formEl.resetFields()
    }

    const handleBeforeClose = (done) => {
      done()
    }

    onMounted(() => {
      questionId.value = route.query.questionId
    })
    let param = toRefs(data)
    return {
      ...param,
      labelPosition,
      formSize,
      ruleFormRef,
      ruleForm,
      rules,
      submitForm,
      handleBeforeClose,
      tagsList,
      resetForm,
      isDisable
    }
  },
}
</script>

<style scoped>
.el-checkbox-button {
  margin-right: 8px;
}
</style>
