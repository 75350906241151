<template>
  <div class="developer-layout APIdocumentation">
    <el-container>
      <Header></Header>
      <el-main>
        <div class="tool-nav" :class="{ sticky: isShow }">
          <ul>
            <li
              :class="{
                active: active === item.id,
              }"
              v-for="(item, index) in toolsList"
              :key="item.name"
              @click="changeTopNav(item)"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>

        <div v-if="isShow" style="height: 72px"></div>
        <div class="main-content">
          <el-container>
            <el-aside width="272px" :class="{ sticky: isShow }">
              <el-button
                v-if="showVideoBtn"
                class="video-course"
                :class="{ active: conShow }"
                @click="toVideoPage"
                color="#41B883"
                plain
                icon="VideoPlay"
                >视频教程
              </el-button>
              <div class="sort-con-title">
                <div
                  class="sort-list"
                  v-for="item in categoryList"
                  :key="item.id"
                >
                  <h1>{{ item.sort }}</h1>
                  <ul>
                    <li
                      v-for="subitem in item.sortList"
                      @click="toDetail(subitem.id)"
                      :class="{
                        active: subitem.id == $route.params.id,
                      }"
                      :key="subitem.id"
                    >
                      {{ subitem.title }}
                    </li>
                  </ul>
                  <el-divider />
                </div>
              </div>
            </el-aside>
            <el-main :class="{ ml272: isShow }">
              <!-- <keep-alive> -->
                <router-view></router-view>
              <!-- </keep-alive> -->
            </el-main>
          </el-container>
        </div>
      </el-main>
      <el-footer> </el-footer>
    </el-container>
  </div>
</template>
<script setup>
import {
  markRaw,
  reactive,
  ref,
  shallowRef,
  onUnmounted,
  onMounted,
  watch,
  nextTick,
  computed,
} from 'vue'
import Header from '@/components/layouts/Header'
import videoTutorial from '@/components/technicalGuide/videoTutorial'
import documentContent from '@/components/technicalGuide/documentContent'
import { cmsCataList, cmsList, cmsDetails } from '@/utils/contentManage'
import { useRouter, useRoute } from 'vue-router'

import { marked } from 'marked'
import 'github-markdown-css/github-markdown.css'

const toolsList = ref([])
const categoryList = ref([])
const isShow = ref(false)
const active = ref(0)
const showVideoBtn = ref(false)

const router = useRouter()
const route = useRoute()
const conShow = computed(() => route.name === 'Video')

const handleScroll = () => {
  const top = document.documentElement.scrollTop
  isShow.value = top > 64
}
window.addEventListener('scroll', handleScroll)

// 跳转到文章详情
const toDetail = (id) => {
  router.push('/TechnicalSupport/content/' + id)
}

// 跳转到视频详情
const toVideoPage = () => {
  router.push('/TechnicalSupport/video')
}

// 获取顶部导航栏的数据
const getNavData = async () => {
  let formdata = new FormData()
  formdata.append('pid', 1)
  const {
    data: { data, code },
  } = await cmsCataList(formdata)
  return data.list
}

// 获取侧边栏的数据
const getAsideData = async () => {
  let formdata = new FormData()
  formdata.append('pid', active.value)
  const {
    data: { data, code },
  } = await cmsCataList(formdata)
  return data?.list
}

// 获取所有文章相关的标题数据
const getArticleData = async (newList) => {
  const newListResult = await Promise.all(
    newList.map((item) => {
      const formData = new FormData()
      formData.append('cata_id', item.id)
      formData.append('page', 1)
      formData.append('pagesize', 10000)
      formData.append('type', 0)
      return cmsList(formData)
    })
  )
  return newListResult
    .map((item) => item.data.data.list.sort((a, b) => a.id - b.id))
    .map((item) => ({
      id: newList.find((i) => i.id === item?.[0]?.cata_id)?.id,
      sort: newList.find((i) => i.id === item?.[0]?.cata_id)?.name,
      sortList: item,
    }))
}

// 检查是否有视频
const checkHasVideo = async (id) => {
  const formData = new FormData()

  // 1、获取当前全部课程的 id
  formData.append('pid', id)
  const {
    data: { data: allVideoTypeResult },
  } = await cmsCataList(formData)
  if (allVideoTypeResult.list.length <= 0) return false

  // 2、根据当前全部课程的 id 获取下面的课程分类
  formData.delete('pid')
  formData.append('pid', allVideoTypeResult.list.at(0).id)
  const {
    data: { data: videoTypeResult },
  } = await cmsCataList(formData)

  // 3、根据课程分类的信息去获取课程下面有没有视频
  const ids = videoTypeResult.list.map((item) => item.id).join(',')
  formData.delete('pid')
  formData.append('cata_id', ids)
  formData.append('type', 2)
  formData.append('page', 1)
  formData.append('pagesize', 10000)
  const {
    data: { data },
  } = await cmsList(formData)
  return data.list.length > 0
}

// 加载页面数据
const loadPageData = async () => {
  const asideList = await getAsideData()
  const newList = asideList.filter((item) => item.name != '视频教程')
  categoryList.value = await getArticleData(newList)
  const video = asideList.find((it) => it.name == '视频教程')
   console.log(video)
  showVideoBtn.value = await checkHasVideo(video.id)
  
  localStorage.setItem('video-id', video.id)
  const currentId =
    route.params.id ?? categoryList.value.at(0).sortList.at(0).id
  nextTick(() => {
    if (route.name !== 'Video') {
      router.replace('/TechnicalSupport/content/' + currentId)
    }
  })
}


// 初始化
const initData = async () => {
  toolsList.value = await getNavData()
  active.value = parseInt(localStorage.activeTop) || toolsList.value?.at(0)?.id
  loadPageData()
}

// 修改顶部菜单选种项
const changeTopNav = (item) => {
  showVideoBtn.value = false
  router.push('/TechnicalSupport')
  active.value = item.id
  localStorage.activeTop = item.id
  loadPageData()
}

onMounted(() => {
  initData()
})
onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll)
  localStorage.activeTop = null
})
</script>

<style scoped>
.ml272 {
  margin-left: 272px;
}
.tool-nav ul {
  height: 72px;
  background: #fff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.08),
    0px 6px 16px 4px rgba(0, 0, 0, 0.04);
  display: flex;
  align-items: center;
  padding-left: 45px;
  width: 100%;
}
.tool-nav ul li {
  float: left;
  padding: 9px 20px;
  margin-right: 12px;
  font-size: 14px;
  color: #606266;
  cursor: pointer;
}
.tool-nav .active {
  background: #f0f2f5;
  color: #00abc8;
}
.tool-nav.sticky {
  position: fixed;
  width: 100vw;
  box-sizing: border-box;
  left: 0;
  top: 0;
  z-index: 2000;
  transition: 0.3s ease-in;
}
.video-course.active {
  border-radius: 4px;
  border: 1px solid #41b883;
  background: #41b883;
  color: #fff;
}
.el-aside.sticky {
  position: fixed;
  /* width: 100vw; */
  /* box-sizing: border-box; */
  left: 0;
  top: 72px;
  z-index: 2000;
  transition: 0.3s ease-in;
}
.main-content,
.el-container {
  width: 100%;
  height: 100%;
  position: relative;
}
.main-content .el-aside {
  padding: 32px 8px 0 32px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: auto;
  border-right: 1px solid #e4e7ed;
  height: calc(100vh - 72px);
}
.main-content .el-aside .el-button.is-plain {
  width: 208px;
  box-sizing: border-box;
  height: 40px;
  font-size: 14px;
}
.sort-con-title {
  height: calc(100vh - 150px);
  overflow-y: scroll;
  padding-right: 16px;
}
.sort-con-title::-webkit-scrollbar {
  width: 4px;
}
.sort-con-title::-webkit-scrollbar-thumb {
  background-color: #e6e6e6;
  border-radius: 4px;
}
.sort-list ul li {
  cursor: pointer;
  width: 100%;
  padding: 9px 16px 9px 16px;
  padding-left: 16px;
  box-sizing: border-box;
}
.sort-list ul li.active {
  border-radius: 8px;
  background: #e6ffff;
  color: #00abc8;
}
.sort-list h1 {
  font-weight: 600;
  margin-top: 32px;
  margin-bottom: 8px;
  font-size: 16px;
  color: #303133;
}

.el-footer {
  height: 0;
}
</style>
