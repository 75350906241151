import axios from "axios"
import {
  ElMessage
} from 'element-plus'
import router from '@/router/index'
import cookies from 'vue-cookies' //引入cookie

// 创建axios实例
const service = axios.create({
  baseURL: developerConfig.nowProjectAbUrl,
  // baseURL: developerConfig.tmp,
  // 超时
  timeout: 1000 * 60
})

service.interceptors.request.use(
  (config) => {
    config.headers.token = cookies.get("token")
    if (localStorage.getItem('group_id')) {
      const group_id = localStorage.getItem('group_id');
      if (config?.data) {
        if (!config.data.get('group_id')) {
          config.data.set('group_id', group_id);
        }
      } else if (config?.params) {
        config.params.group_id = group_id;
      }
    }
    // console.log(JSON.stringify(config,null,2),"-----------------------------")
    return config
  },
  (error) => {
    return Promise.reject(new Error(error))
  }
)
service.interceptors.response.use(
  (response) => {
    const {
      data,
      code,
      msg
    } = response.data
    if (response.status === 200 && code === 1) {
      return response
    } else if (response.status === 200 && code === 0) {
      ElMessage.error(msg)
      return Promise.reject(new Error(msg))
    }
  },
  (error) => {
    console.log(error)
    if (error.response && error.response.status === 401) {
      // router.push("/Login")
      var nHref = window.location.href
      if (nHref.indexOf("#") != -1) {
        nHref = nHref.split("#")[0]
      }
      window.open(developerConfig.ssoPageUrl + '?target=' + nHref, '_self') 
      return Promise.reject(new Error(error.response.data.msg))
    }
    error.response && ElMessage.error(error.response.data.msg)
    return Promise.reject(new Error(error.response.data.msg))
  }
)

export default service