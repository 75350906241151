import {
    createApp
} from 'vue'
import App from './App.vue'
import router from './router'

//import '@/router/permission'



import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import "./assets/css/init.css"


import animated from "animate.css";
import store from './store'
import cookies from 'vue-cookies' //引入cookie
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css'




const app = createApp(App)
app.provide('$cookies', cookies)
// 统一注册Icon图标
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.component('QuillEditor', QuillEditor)
app.use(ElementPlus).use(router).use(store).use(animated).use(cookies).mount('#app')