<template>
  <div class="document-content">
    <!-- <div>文本父组件传过来的参数：{{ textId }}</div> -->
    <div class="ufjsb">
      <div class="title-box">
        <h1>{{ titleContent }}</h1>
        <!-- <p>更新时间：2023-07-13 21:09</p> -->
      </div>
      <div class="search-box">
        <el-input
          v-model="inputText"
          size="large"
          placeholder="请输入开发相关文字"
          @keypress.enter="contentSearch"
          @input="
            (e) => {
              e === '' && contentSearch()
            }
          "
        >
          <template #prefix>
            <el-icon class="el-input__icon"><search /></el-icon>
          </template>
        </el-input>
        <div class="search-con" v-if="searchConList.length > 0">
          <ul>
            <li
              v-for="item in searchConList"
              :key="item.id"
              @click="searchBtn(item)"
            >
              <!-- <p v-if="item.title.includes(inputText)" v-html="item.title"></p> -->
              <!-- <p class="s-title" v-html="item.title"></p> -->
              <p class="s-con" v-html="item.content"></p>
              <!-- <p v-if="!item.title.includes(inputText)" v-html="extractedContent"></p> -->
            </li>
          </ul>
        </div>
        <div class="search-con" v-if="noDataState"><el-empty :image-size="100" /></div>
        
      </div>
    </div>
    <el-divider />
    <div class="markdown-body" v-html="textContent"></div>
  </div>
</template>

<script>
export default {
  name: 'documentContent',
}
</script>

<script setup>
import { ref, onMounted, defineProps, computed } from 'vue'
import { cmsList } from '@/utils/contentManage'
import { useRouter } from 'vue-router'
import * as marked from 'marked'

//接受父组件传过来的参数
const props = defineProps({
  textContent: {
    type: String,
    default: '',
  },
  titleContent: {
    type: String,
    default: '',
  },
})
const noDataState = ref(false)
const inputText = ref('')
const searchConList = ref([])
// const extractedContent = ref([])
const paragraphs = ref([])
function stripHtmlTags(input) {
  return input.replace(/<[^>]*>/g, '')
}
function containsHTML(str) {
  // 正则表达式检查是否存在标签 <...>
  const htmlRegex = /<[^>]*>/;
  return htmlRegex.test(str);
}
const contentSearch = async () => {
  searchConList.value = ''
  noDataState.value = false
  let formdata = new FormData()
  if (inputText.value) {
    formdata.append('keywords', inputText.value)
    formdata.append('type', 0)
    const data = await cmsList(formdata)
    if (data.data.code == 1) {
      if (data.data.data.list.length > 0) {
        searchConList.value = data.data.data.list
        data.data.data.list.forEach((item) => {
          if (inputText.value) {
            //匹配关键字正则
            let replaceReg = new RegExp(inputText.value, 'g')
            // 高亮替换v-html值
            let replaceString =
              '<span class="highlight">' + inputText.value + '</span>'
            item.title = item.title.replace(replaceReg, replaceString)
            // searchConList.value.push(item)

            var flag = true
            var text = item.content
            var stringWithoutTags=''
            // 使用正则表达式匹配关键字
            var regex = new RegExp(inputText.value, 'gi')
            if (regex.test(text)) {
              var highlightedTextInParagraph = text.replace(
                regex,
                '<span class="highlight">$&</span>'
              )
              if (flag == true) {
                highlightedTextInParagraph
                stringWithoutTags = stripHtmlTags(
                  highlightedTextInParagraph
                )
                console.log(stringWithoutTags)
              }
              flag = false
            }
            stringWithoutTags = stringWithoutTags.replace(
              regex,
              '<span class="highlight">$&</span>'
            )

            const position = stringWithoutTags.indexOf(
              '<span class="highlight">' + inputText.value + '</span>'
            )
            // if (position !== -1) {
            // console.log(`找到字符串在索引 ${position}`)
            const start = position
            const end = position + 31 + inputText.value.length + 100
            const subString = stringWithoutTags.substring(start, end)
            console.log(subString) // 输出 "world"

            item.content = subString

          }
        })
      }else{
        noDataState.value = true
      }
    }
  }
}
const router = useRouter()
//console.log(router)
const searchBtn = (item) => {
  var pPath = router.currentRoute.value.matched[0].path
  if (pPath === '/TechnicalSupport') {
    router.push('/TechnicalSupport/content/' + item.id)
  } else {
    router.push('/DevelopmentTool/contentDetailTool/' + item.id)
  }
}
onMounted(() => {})
</script>

<style scoped>
mark {
  background-color: yellow;
  color: black;
}
.document-content {
  width: 77%;
  margin: 78px auto 0;
  min-height: calc(100vh - 72px);
}
.title-box h1 {
  font-size: 40px;
  font-weight: normal;
  color: #303133;
}
.title-box p {
  font-size: 14px;
  color: #909399;
  margin-top: 16px;
}
.search-box {
  width: 25vw;
  position: relative;
}
.search-con {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #e4e7ed;
  background: #fff;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.12);
  height: 386px;
  position: absolute;
  top: 50px;
  left: 0;
  z-index: 1000;
  overflow-y: scroll;
  padding-right: 5px;
  box-sizing: border-box;
}
.search-con::-webkit-scrollbar {
  width: 4px;
}
.search-con::-webkit-scrollbar-thumb {
  background-color: #e6e6e6;
  border-radius: 4px;
}
.search-con ul li {
  /* height: 32px; */
  /* line-height: 32px; */
  padding-left: 20px;
  box-sizing: border-box;
  cursor: pointer;
  border-bottom: 1px solid #e4e7ed;
  padding-top: 5px;
  padding-bottom: 5px;
}
.search-con ul li:hover {
  background: #f5f7fa;
  /* color: #fff; */
}
.search-con ul li p {
  font-size: 14px;
  word-wrap: break-word;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 10;
  overflow: hidden;
}
.s-title {
  color: #303133;
  line-height: 22px;
}
.s-con {
  color: #606266;
  line-height: 22px;
}
.search-con .s-con p {
  font-size: 12px;
}
</style>
