import {
    ElMessage
} from 'element-plus'

export const messageInf = (message, type) => {

    if (typeof (message) == 'string') {
        ElMessage({
            message: message,
            type: type
        })
    } else {
        if (message.status === 200 && message.data.code === 1) {
            ElMessage({
                message: message.data.msg,
                type: "success"
            })
        }
    }
}

function getRandom(min, max) {
    return Math.round(Math.random() * (max - min) + min);
}
export const getCode = () => {
    let code = '';
    for (var i = 0; i < 6; i++) {
        var type = getRandom(1, 3);
        switch (type) {
            case 1:
                code += String.fromCharCode(getRandom(48, 57)); //数字
                break;
            case 2:
                code += String.fromCharCode(getRandom(65, 90)); //大写字母
                break;
            case 3:
                code += String.fromCharCode(getRandom(97, 122)); //小写字母
                break;
        }
    }
    return code;
}