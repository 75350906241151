<template>
  <el-dropdown>
    <el-avatar :size="24" :src="userImage" @error="errorHandler">
      <img
        src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgaGVpZ2h0PSIxMDAiIHdpZHRoPSIxMDAiPjxyZWN0IGZpbGw9InJnYigyMTEsMTYwLDIyOSkiIHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAiIGhlaWdodD0iMTAwIj48L3JlY3Q+PHRleHQgeD0iNTAiIHk9IjUwIiBmb250LXNpemU9IjUwIiB0ZXh0LWNvcHk9ImZhc3QiIGZpbGw9IiNmZmZmZmYiIHRleHQtYW5jaG9yPSJtaWRkbGUiIHRleHQtcmlnaHRzPSJhZG1pbiIgZG9taW5hbnQtYmFzZWxpbmU9ImNlbnRyYWwiPkE8L3RleHQ+PC9zdmc+" />
    </el-avatar>
    <template #dropdown>
      <el-dropdown-menu>
        <el-dropdown-item disabled>
          <div>
            <div style="font-size: 12px; color: #00abc8">{{ useName }}</div>
            <div style="font-size: 12px; color: #c0c4cc">{{ emailName }}</div>
          </div>
        </el-dropdown-item>

        <!-- <el-dropdown-item divided>我的主页</el-dropdown-item> -->
        <!-- <el-dropdown-item @click="organizeManage" divided>工作空间</el-dropdown-item> -->
        <!-- <el-dropdown-item>我的订单</el-dropdown-item> -->
        <!-- <el-dropdown-item @click="visitSource">资源中心</el-dropdown-item> -->
        <!-- <el-dropdown-item @click="webSite">访问官网</el-dropdown-item> -->
        <!-- <el-dropdown-item @click="acountSet" divided>账号设置</el-dropdown-item> -->
        <el-dropdown-item @click="logout">退出登录</el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<script>
import { onBeforeMount, ref, watch } from 'vue'
import { useStore } from 'vuex'
import router from '@/router'
export default {
  name: '',
  setup() {
    const store = useStore()
    const userImage = ref(
      'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgaGVpZ2h0PSIxMDAiIHdpZHRoPSIxMDAiPjxyZWN0IGZpbGw9InJnYigyMTEsMTYwLDIyOSkiIHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAiIGhlaWdodD0iMTAwIj48L3JlY3Q+PHRleHQgeD0iNTAiIHk9IjUwIiBmb250LXNpemU9IjUwIiB0ZXh0LWNvcHk9ImZhc3QiIGZpbGw9IiNmZmZmZmYiIHRleHQtYW5jaG9yPSJtaWRkbGUiIHRleHQtcmlnaHRzPSJhZG1pbiIgZG9taW5hbnQtYmFzZWxpbmU9ImNlbnRyYWwiPkE8L3RleHQ+PC9zdmc+'
    )
    const useName = ref('')
    const emailName = ref('')
    onBeforeMount(() => {
      let inf = JSON.parse(localStorage.getItem('userData'))
      if (inf && inf.email) {
        emailName.value = inf.email
      }
      if (inf && inf.username) {
        useName.value = inf.username
      }
      if (inf && inf.avatar) {
        if (inf.avatar.includes('http')) 
        userImage.value = inf.avatar
        else 
        userImage.value = developerConfig.userCenterUrl + inf.avatar
      }
    })
    watch(
      () => store.state.app.userData,
      (count, prevCount) => {
        useName.value = count.username
        emailName.value = count.email
        // debugger
        if (count.avatar.includes('http'))
          userImage.value = count.avatar
        else
          userImage.value = developerConfig.userCenterUrl + count.avatar
      }, {
        deep: true,
      }
    )

    const acountSet = () => {
      router.push('/PersonalCenter')
    }

    const logout = () => {
      store.dispatch('app/logout')
    }

    const visitSource = () => {
      window.open('http://10.11.2.90:82/', '_blank')
    }

    const organizeManage = () => {
      router.push('/OrganizeManage')
    }

    const errorHandler = () => true
    const webSite = () => {
      router.push('/Home')
    }
    return {
      userImage,
      useName,
      acountSet,
      logout,
      visitSource,
      organizeManage,
      errorHandler,
      webSite,
      emailName,
    }
  },
}
</script>

<style scoped></style>
