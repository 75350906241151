<template>
  <div class="w1280 footer">
    <el-row justify="space-between" :gutter="40">
      <el-col :span="16">
        <el-row>
          <el-col :span="12" class="logo-new">
            <img src="../../assets/imgs/logo_new.png" alt="" />
            <p>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.46324 6.23123C6.0095 7.1918 6.8082 7.9905 7.76877 8.53673L8.28473 7.81439C8.45629 7.57429 8.7801 7.50009 9.0391 7.64155C9.85973 8.08984 10.7666 8.36226 11.7127 8.43716C12.0161 8.46119 12.25 8.71436 12.25 9.01868V11.622C12.25 11.9211 12.0238 12.1717 11.7263 12.2022C11.4172 12.234 11.1053 12.25 10.7917 12.25C5.79809 12.25 1.75 8.2019 1.75 3.20833C1.75 2.89466 1.766 2.58283 1.79777 2.27369C1.82834 1.97619 2.07897 1.75 2.37805 1.75H4.98133C5.28564 1.75 5.53883 1.98394 5.56284 2.2873C5.63772 3.23335 5.91016 4.14029 6.35845 4.96092C6.49991 5.2199 6.42571 5.54373 6.18561 5.71525L5.46324 6.23123ZM3.99248 5.84803L5.10078 5.05639C4.78652 4.378 4.57116 3.65857 4.46091 2.91667H2.92196C2.91843 3.01369 2.91667 3.11092 2.91667 3.20833C2.91667 7.55755 6.44245 11.0833 10.7917 11.0833C10.8891 11.0833 10.9863 11.0816 11.0833 11.078V9.53907C10.3414 9.42882 9.62202 9.21346 8.94361 8.89922L8.15197 10.0075C7.83172 9.88312 7.52243 9.73671 7.22598 9.57022L7.19209 9.55092C6.04899 8.90091 5.09911 7.95101 4.44908 6.80791L4.4298 6.77402C4.26329 6.47757 4.1169 6.16828 3.99248 5.84803Z"
                  fill="#606266"
                />
              </svg>
              0755-26608956
            </p>
            <p>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.74996 1.75H12.25C12.5721 1.75 12.8333 2.01117 12.8333 2.33333V11.6667C12.8333 11.9888 12.5721 12.25 12.25 12.25H1.74996C1.4278 12.25 1.16663 11.9888 1.16663 11.6667V2.33333C1.16663 2.01117 1.4278 1.75 1.74996 1.75ZM11.6666 4.22212L7.04184 8.36383L2.33329 4.2093V11.0833H11.6666V4.22212ZM2.63164 2.91667L7.03607 6.80283L11.3755 2.91667H2.63164Z"
                  fill="#606266"
                />
              </svg>
              info@eulee.cn
            </p>
            <p>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 13.8413L3.28769 10.129C1.23744 8.0787 1.23744 4.75461 3.28769 2.70436C5.33794 0.654103 8.66203 0.654103 10.7123 2.70436C12.7626 4.75461 12.7626 8.0787 10.7123 10.129L7 13.8413ZM9.88732 9.30399C11.482 7.70939 11.482 5.12395 9.88732 3.52931C8.29272 1.93467 5.70729 1.93467 4.11265 3.52931C2.51801 5.12395 2.51801 7.70939 4.11265 9.30399L7 12.1914L9.88732 9.30399ZM7 7.58333C6.35565 7.58333 5.83333 7.06102 5.83333 6.41667C5.83333 5.77233 6.35565 5.25 7 5.25C7.64435 5.25 8.16667 5.77233 8.16667 6.41667C8.16667 7.06102 7.64435 7.58333 7 7.58333Z"
                  fill="#606266"
                />
              </svg>
              深圳市南山区高新中三道9号环球数码大厦A厅8楼
            </p>
          </el-col>
          <el-col :span="12" class="link_">
            <el-row>
              <!-- <el-col :span="8">
                <h3>开发文档</h3>
                <el-link :underline="false" @click="openXQ('/udStreamSDKGuide')"
                  >开发指南</el-link
                ><br />
                <el-link :underline="false" @click="openXQ('/udStreamSDK')"
                  >API文档</el-link
                ><br />
                <el-link :underline="false" @click="openXQ('/udStreamSDKCase')"
                  >开发指南</el-link
                >
              </el-col>
              <el-col :span="8">
                <h3>技术支持</h3>
                <el-link :underline="false" @click="openHS('/udStream')">技术指南</el-link><br />
                <el-link :underline="false" @click="openHS('/TechnicalLearning')">技术学习</el-link>
                <el-link :underline="false" @click="openHS('/TechnicalFAQ')">技术FAQ</el-link>
              </el-col> -->
              <el-col :span="8">
                <h3>关于我们</h3>
                <el-link
                  :underline="false"
                  href="https://cloud.eulee.cn/#/Home"
                  target="_blank"
                  >优立云世界</el-link
                ><br />
                <el-link
                  :underline="false"
                  href="https://www.eulee.cn/"
                  target="_blank"
                  >优立官网</el-link
                >
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="6" class="erweim-a">
        <el-row>
          <el-col :span="12" style="text-align: center">
            <img src="../../assets/imgs/wx_gzh.png" alt="" />
            <p>微信公众号</p>
          </el-col>
          <el-col :span="12" style="text-align: center">
            <img src="../../assets/imgs/wx-kf.png" alt="" />
            <p>微信客服</p>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
  <div class="c1280 bottom">
    Copyright@2016-2022 优立科技All Rights Reserved.
    <el-link
      href="https://beian.miit.gov.cn/#/Integrated/recordQuery"
      target="_blank"
      style="color: #a8abb2"
      >粤ICP备16053295</el-link
    >
    <el-link @click="openAgreement(1)" style="margin-right: 0"
      >《用户协议》</el-link
    >
    <el-link @click="openAgreement(2)">《隐私政策》</el-link>
  </div>
</template>

<script>
import { ref, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
export default {
  name: '',
  setup() {
    const router = useRouter()
    const store = useStore()
    const erShow = ref(false)
    const mouseOver = (value) => {
      erShow.value = true
    }
    const mouseLeave = (value) => {
      erShow.value = false
    }
    const openAgreement = (value) => {
      if (value == 1) router.replace('/UserAgreement')
      else if (value == 2) router.replace('/PrivacyPolicy')
    }
    const openXQ = (key) => {
      router.push(key)
    }
    const openHS = (key) => {
      router.push(key)
    }
    onMounted(() => {
    })
    return {
      mouseOver,
      mouseLeave,
      erShow,
      openAgreement,
      openXQ,
      openHS
    }
  },
}
</script>

<style scoped>
.footer {
  padding-top: 48px;
  height: 226px;
  box-sizing: border-box;
}
.footer_l .phone {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.9);
}
.footer_l .email {
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.9);
  margin-top: 8px;
  margin-bottom: 8px;
}
.footer_l .footer_l-r .el-button {
  color: rgba(0, 0, 0, 0.45);
}
.el-button--text:not(.is-disabled):focus,
.el-button--text:not(.is-disabled):hover {
  color: rgba(0, 0, 0, 0.9);
}
.footer_l-r .el-button svg {
  margin-right: 5px;
}
.el-link {
  color: rgba(0, 0, 0, 0.9);
  font-weight: 500;
  font-size: 12px;
  margin-right: 20px;
}
.title {
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.9);
}
.third-party .wx-er {
  background-color: #fff;
  bottom: 36px;
  left: 52px;
  border: 4px solid #2ebad2;
}
.bottom {
  height: 48px;
  line-height: 48px;
  text-align: center;
  color: #a8abb2;
  font-weight: 500;
  font-size: 14px;
  background: #f2f3f5;
  border-width: 1px 0px 0px 0px;
  border-style: solid;
  border-color: #dcdfe6;
}
.bottom .el-link {
  font-weight: 500;
  font-size: 14px;
  color: #2ebad2;
  margin-left: 8px;
}
.el-link:hover {
  color: #2EBAD2 !important;
}
.logo-new img {
  margin-bottom: 18px;
}
.logo-new p {
  font-size: 14px;
  color: #606266;
  line-height: 22px;
}
.logo-new p svg {
  margin-right: 5px;
}
.link_ h3 {
  line-height: normal;
  font-size: 16px;
  color: #303133;
  margin-bottom: 16px;
}
.link_ .el-link {
  margin-bottom: 12px;
  color: #606266;
  font-size: 14px;
}
.erweim-a p{
  color: #303133;
  font-size: 14px;
  margin-top: 7px;
}
.erweim-a .el-col{
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
}
</style>
